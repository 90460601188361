import { createContext, useState, useContext } from "react";
import AuthContext from "./AuthContext";
import { fetchLocations } from "entities/locations/service/fetchLocations";
import fetchWorkers from "utils/fetchWorkers";
import { sortLocation } from "entities/locations/service/sortLocation";
import { dateToString } from "shared/formatDate";

const LocationContext = createContext();

export const LocationProvider = ({ children }) => {
  const initialSearchParams = {
    Id: null,
    INN: null,
    MeasuringBlockTypeId: null,
    AssessmentTypeId: null,
    DepartmentId: null,
    FromWorkspaceCount: null,
    ToWorkspaceCount: null,
    FromPublicationDate: null,
    ToPublicationDate: null,
    FromAcceptingOffersDeadline: null,
    ToAcceptingOffersDeadline: null,
    FromMeasurementsDate: null,
    ToMeasurementsDate: null,
    SearchString: null,
    FromConclusionContractDate: null,
    ToConclusionContractDate: null,
    FromPlannedExecutionDate: null,
    ToPlannedExecutionDate: null,
  };
  const initialSortParams = {
    SortMethod: null,
    SortField: null,
  };
  const sortFields = {
    id: "ID",
    assessmentTypeList: "Тип оценки",
    customerINN: "ИНН заказчика",
    // regionName: 'Регион',
    additionalName: "Доп. наименование",
    contactData: "Контактные данные",
    workspaceCount: "Количество РМ",
    workerName: "Замерщик",
    measurementsDate: "Дата замеров",
    fromPlannedMeasurementsDate: "Плановая дата замеров",
  };
  const additionalFields = {
    assessmentTypeList: "Тип оценки",
    customerINN: "ИНН заказчика",
    workerName: "Замерщик",
    additionalName: "Доп. наименование",
    contactData: "Контактные данные",
    workspaceCount: "Количество РМ",
    measurementsDate: "Дата замеров",
    fromPlannedMeasurementsDate: "Плановая дата замеров",
  };

  const { updateToken } = useContext(AuthContext);

  const [isLoading, setIsLoading] = useState(false);
  const [values, setValues] = useState(initialSearchParams);
  const [sortParams, setSortParams] = useState(initialSortParams);
  const [sortIsShowed, setSortIsShowed] = useState(false);
  const [searchIsShowed, setSearchIsShowed] = useState(false);
  const [selectedSortField, setSelectedSortField] = useState();
  const [selectedAdditionalField, setSelectedAdditionalField] = useState();
  const [page, setPage] = useState(1);
  const [workers, setWorkers] = useState([]);
  const [prevPage, setPrevPage] = useState(page);
  const [totalCountPages, setTotalCountPages] = useState(1);
  const [locations, setLocations] = useState([]);
  const [location, setLocation] = useState();
  const [locationAll, setLocationAll] = useState([]);
  const [locationType, setLocationType] = useState();
  const [editPageStatus, setEditPageStatus] = useState();

  const [isCompare, setIsCompare] = useState(false);

  const getLocations = (stage, locType, sortNull = null, isCompare) => {
    if (stage.query === null) return;
    if (prevPage !== page) {
      setPrevPage(page);
    } else {
      if (!sortNull)
        setSelectedSortField(
          sortParams.SortField ? sortParams.SortField : null
        );
      else setSelectedSortField(null);
    }
    Object.keys(values).forEach((key) => {
      if (key.includes("Date")) {
        values[key] = dateToString(values[key]);
      }
    });
    const params = { ...sortParams, ...values };
    if (isCompare) {
      fetchLocations(params, page, "", "Contract", 2500, true).then((res) => {
        if (res === 401 || res === 403) {
          updateToken();
          return;
        }
        let potentialMB = res.list;

        fetchLocations({}, page, "", "Potential", 2500, true).then((res) => {
          if (res === 401 || res === 403) {
            updateToken();
            return;
          }

          potentialMB = sortLocation(potentialMB, res.list);
          setLocations(potentialMB.slice(0, 50));
          setLocationAll([...potentialMB]);
          setTotalCountPages(Math.ceil(potentialMB.length / 50));
          setIsLoading(false);
        });
      });
    } else {
      fetchLocations(params, page, stage.query, locType).then((res) => {
        if (res === 401 || res === 403) {
          updateToken();
          return;
        }

        setLocations(res.list);
        setTotalCountPages(res.totalPagesCount);
        setIsLoading(false);
      });
    }
  };

  const getWorkers = () => {
    fetchWorkers(setWorkers);
  };

  const initLocation = (typeId) => {
    let baseObject = {
      additionalName: null,
      departmentId: null,
      regions: [],
      assessmentTypeList: [],
      workspaceCount: null,
      executionDescription: null,
      contactData: null,
    };
    let contractFields = {
      contractId: null,
      contract: null,
      plannedMeasurementsDate: null,
      measurementsDate: null,
      measurementStatusId: null,
      worker: [],
    };
    let tenderFields = {
      tender: null,
      publicationDate: null,
      acceptingOffersDeadline: null,
    };
    switch (typeId) {
      case "Potential":
        setLocation({ ...baseObject, ...tenderFields });
        break;
      case "Contract":
        setLocation({ ...baseObject, ...contractFields });
        break;
      default:
        break;
    }
    setLocationType(typeId);
  };

  const updateLocation = (key, value) => {
    setLocation((prev) => {
      return {
        ...prev,
        [key]: value,
      };
    });
  };
  return (
    <LocationContext.Provider
      value={{
        initialSearchParams,
        initialSortParams,
        isLoading,
        setIsLoading,
        sortFields,
        additionalFields,
        values,
        workers,
        getWorkers,
        setValues,
        isCompare,
        setIsCompare,
        sortParams,
        setSortParams,
        sortIsShowed,
        setSortIsShowed,
        searchIsShowed,
        setSearchIsShowed,
        selectedSortField,
        setSelectedSortField,
        selectedAdditionalField,
        setSelectedAdditionalField,
        setLocationAll,
        page,
        setPage,
        prevPage,
        setPrevPage,
        totalCountPages,
        setTotalCountPages,
        locations,
        setLocations,
        getLocations,
        location,
        setLocation,
        updateLocation,
        initLocation,
        locationType,
        setLocationType,
        editPageStatus,
        setEditPageStatus,
      }}
    >
      {children}
    </LocationContext.Provider>
  );
};

export default LocationContext;
