import React, { useState } from 'react';
import { Link } from 'react-router-dom'
import BlockModal from './BlockUserModal';
import UnblockModal from './UnblockModal';

import { MdEdit } from 'react-icons/md';
import { FaBan } from 'react-icons/fa';
import { FaUnlock } from 'react-icons/fa';

const AdminActionButtons = (note) => {
  const [openBlockModal, setBlockModal] = useState(false);
  const [openUnblockModal, setUnblockModal] = useState(false);



  return <>
    <Link to={note.link} title={'Редактировать'} state={{ user: note }}>
      <button className="button button--square" title='Редактировать'>
        <MdEdit className='icon-edit' />
      </button>
    </Link>

    <button onClick={() => setBlockModal(true)} className={`button button--square`}
      disabled={!note.state.isActive}
      title='Заблокировать'>
      <FaBan className={`icon-ban`} />
    </button>
    {
      openBlockModal ? <BlockModal closeModal={setBlockModal} user={note} /> : null
    }

    <button onClick={() => setUnblockModal(true)} className="button button--square" title='Разблокировать'
      disabled={note.state.isActive}
    >
      <FaUnlock className='icon-ban' />
    </button>
    {
      openUnblockModal ? <UnblockModal closeModal={setUnblockModal} user={note} /> : null
    }
  </>;
};

export default AdminActionButtons;






