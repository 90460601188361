const INCOME = [
  "Месяц",
  "Общая сумма договоров",
  "Общая сумма исполнения",
  "Расторгнутые",
  "Общая сумма исполненных договоров",
  'Общая сумма по актам',
];

const INCOME_PLANNED = [
  "Месяц",
  "Общая сумма договоров",
];

const RETURN_OF_SECURITY = [
  "Месяц",
  "Общая сумма обеспечений",
  "Сумма возврата обеспечения",
  "Остаток обеспечения",
];

const EXPENSES = ["Описание", "Сумма расхода", "Дата"];

const TOTAL_EXPENSES = ["Месяц", "Сумма расходов"];

const WORKLOAD = [
  ["#"],
  ["Сотрудник"],
  ["Сбор информации", "Кол-во проектов / Кол-во РМ"],
  ["Готово к работе", "Кол-во проектов / Кол-во РМ"],
  ["Принято в работу", "Кол-во проектов / Кол-во РМ"],
  ["Общее кол-во РМ"],
  ["Согласование", "Кол-во проектов / Кол-во РМ"],
  ["Распечатан/Отправлен/Выгрузка", "Кол-во проектов / Кол-во РМ"],
  ["Итого"]
];

const INCOME_BY_PAID = [
  ["Месяц"],
  ["Сумма оплаты"],
  ["Сумма Стандарт-Мед"],
  ["Расход"],
  ["Сумма к оплате Стандарт-Мед"],
  ["Сумма оплаты Стандарт-Мед"],
  ["Долг ПРЦОТ", " на конец месяца"],
];

const AGENT_REPORTS = [["ID"], ["Месяц - Год"], ["Скачать отчёт"]];

export const getHeaders = (tableType, type) => {
  switch (tableType) {
    case "income":
      if(type === 'plannedDate')
      return INCOME_PLANNED;
      else
      return INCOME;
    case "returns":
      return RETURN_OF_SECURITY;
    case "expenses":
      return EXPENSES;
    case "total_expenses":
      return TOTAL_EXPENSES;
    case "workload":
      return WORKLOAD;
    case "income_by_paid":
      return INCOME_BY_PAID;
    case "agent_reports":
      return AGENT_REPORTS;
    default:
      return RETURN_OF_SECURITY;
  }
};
