import React, { useState, useEffect, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Sidebar from "../../../../components/Sidebar";
import Asterisk from "../../../../components/Asterisk";
import SelectRegions from "../../../../components/common/SelectRegions";
import postProjectData from "utils/PostProjectData";
import { LAB_DEFAULT_USER_ID } from "utils/DefaultUsersID";
import { LabelTitle } from "shared/ui/LabelTitle/LabelTitle";
import { fetchOrganizations } from "utils/fetchOrganizations";
import { DatePicker } from "components/DateInput/DateInput";
import { TextInput } from "components/TextInput/TextInput";
import { fetchContractByID } from "entities/contracts/service/fetchContractById";
import { Text } from "shared/ui/Text/Text";
import { InputWrapper } from "shared/ui/InputWrapper/InputWrapper";
import { dateToString } from "shared/formatDate";
import { Select } from "components/Select/Select";
import { fetchAgents } from "utils/fetchAgents";
import { api, showValidateMessage } from "settings/Api";
import fetchBranchOffices from "utils/fetchBranchOffices";
import AuthContext from "context/AuthContext";

const NewProjectPage = () => {
  const { state } = useLocation();
  const { projectId, actionType } = state;
  const { role, accessRights } = useContext(AuthContext);

  const isEditPage = actionType === "edit";

  const navigate = useNavigate();

  const [values, setValues] = useState({});
  const [tender, setTender] = useState({});
  const [filials, setFilials] = useState([]);

  useEffect(() => {
    fetchRegions();
    getOrganizations();
    if (isEditPage) {
      fetchTender();
    }
  }, []);

  useEffect(() => {
    fetchUsers("Laboratory");
  }, [values?.branchOfficeId]);
  const [isNullRM, setIsNullRM] = useState(false);
  useEffect(() => {
    if (isEditPage) {
      setValues({
        ...values,
        contract: tender?.contract,
        contractDepartmentNumber: tender?.contractDepartmentNumber,
        assessmentTypeId: tender?.assessmentTypeId,
        Regions: tender?.regions,
        haveWorkbook: tender?.haveWorkbook === true ? "true" : "false",
        workspaceCount: tender?.workspaceCount,
        name: tender?.name,
        executionProgramId: tender?.executionProgramId,
        executionDescription: tender?.executionDescription,
        informationRequestDate: tender?.informationRequestDate,
        plannedExecutionDate: tender?.plannedExecutionDate,
        informationReceiptDate: tender?.informationReceiptDate,
        performerAppointedDate: tender?.performerAppointedDate,
        acceptedToWorkDate: tender?.acceptedToWorkDate,
        sendingForApprovalDate: tender?.sendingForApprovalDate,
        contactData: tender?.contactData,
        reportSendingDate: tender?.reportSendingDate,
        titleSheetSignDate: tender?.titleSheetSignDate,
        unloadingDate: tender?.unloadingDate,
        personInCharge: tender?.personInCharge,
        laboratoryUserId: tender?.personInCharge?.id || null,
        departmentId: tender?.departmentId,
        organizationId: tender?.organizationId,
        branchOfficeId: tender?.branchOfficeId,
      });
      setCustomerNameViaINN(tender?.contract?.customer?.name);
      setIsNullRM(!tender?.workspaceCount);
    }
  }, [tender]);

  useEffect(() => {
    if (values?.organizationId)
      fetchBranchOffices(values?.organizationId).then((res) => {
        setFilials(res);
      });
  }, [values?.organizationId]);

  const fetchTender = async () => {
    const token = JSON.parse(localStorage.getItem("authTokens")).data
      .accessToken;
    let response = await api(
      `/api/sout/admin/separated-projects?id=${projectId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    let data = await response.json();
    setTender(data?.data?.list[0]);
  };

  const inputChangeHandler = (e) => {
    const { name, value } = e.target;

    setValues({
      ...values,
      [name]: value,
    });
  };

  const convertDate = (date) => {
    return date ? dateToString(date) : null;
  };

  const updateRegions = (updatedRegions) =>
    setValues({ ...values, Regions: updatedRegions });

  const [laboratoryUsers, getLaboratoryUsers] = useState("");
  const [organizations, setOrganizations] = useState();
  const [regions, setRegions] = useState("");
  const [request_body, set_request_body] = useState();

  useEffect(() => {
    set_request_body({
      assessmentTypeId: values.assessmentTypeId,
      Regions: values.Regions,
      HaveWorkbook: values.haveWorkbook === "true" ? true : false,
      workspaceCount: values?.workspaceCount || null,
      KPP: values.KPP,
      name: values.name,
      executionProgramId: values.executionProgramId,
      contract: values?.contract,
      contactData: values?.contactData,
      plannedExecutionDate: convertDate(values?.plannedExecutionDate),
      // additionalCustomerName: values?.additionalCustomerName,
      executionDescription: values.executionDescription,
      informationRequestDate: convertDate(values.informationRequestDate),
      informationReceiptDate: convertDate(values.informationReceiptDate),
      performerAppointedDate: convertDate(values.performerAppointedDate),
      acceptedToWorkDate: convertDate(values.acceptedToWorkDate),
      sendingForApprovalDate: convertDate(values.sendingForApprovalDate),
      reportSendingDate: convertDate(values.reportSendingDate),
      titleSheetSignDate: convertDate(values.titleSheetSignDate),
      unloadingDate: convertDate(values.unloadingDate),
      contractDepartmentNumber: parseInt(values?.contractDepartmentNumber),
      personInCharge:
        values.laboratoryUserId && values.laboratoryUserId !== "null"
          ? {
              id: values.laboratoryUserId,
              departmentId: "Laboratory",
            }
          : null,
      departmentId: values.departmentId,
      organizationId: values.organizationId,
      branchOfficeId: values.branchOfficeId,
    });
  }, [values]);

  const fetchUsers = async (departmentName) => {
    const token = JSON.parse(localStorage.getItem("authTokens")).data
      .accessToken;
    let response = await api(
      `/api/sout/helpers/handbooks/engineers?department=${departmentName}&branchOfficeId=${values?.branchOfficeId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    let data = await response.json();

    switch (departmentName) {
      case "Laboratory":
        getLaboratoryUsers(data.data);
        break;
      default:
        throw new Error("Fetch error: department cannot found!");
    }
  };

  const fetchRegions = async () => {
    const token = JSON.parse(localStorage.getItem("authTokens")).data
      .accessToken;
    let response = await api("/api/sout/helpers/handbooks/regions", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    let data = await response.json();
    setRegions(data.data);
  };

  const getOrganizations = () => {
    fetchAgents().then((res) => {
      setOrganizations(res);
    });
  };

  const [displayErrors, setDisplayErrors] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    let res;
    isEditPage
      ? (res = await postProjectData(request_body, actionType, projectId))
      : (res = await postProjectData(request_body, actionType));
    console.log(res);
    if (res?.status !== 200) {
      if (res?.status === 400) showValidateMessage(res?.data?.errors);
      if (isEditPage) {
        alert(`Ошибка. Проект не был изменён.`);
      } else {
        alert(`Ошибка. Проект не был добавлен`);
      }
      return false;
    }
    if (isEditPage) {
      alert("Проект успешно изменён");
      navigate(-1);
    } else {
      alert("Проект успешно добавлен");
      navigate("/sout/projects/organizations-analysis");
    }
  };
  const [customerNameViaINN, setCustomerNameViaINN] = useState();

  const [isSearching, setIsSearching] = useState(false);

  const getContractByID = (contractId) => {
    setValues({ ...values, contract: { ...values.contract, id: contractId } });
    fetchContractByID(contractId)
      .then((res) => {
        setValues({ ...values, contract: res });
        setCustomerNameViaINN(res.customer.name);
      })
      .catch((e) => {
        setValues({
          ...values,
          contract: {
            customer: { inn: "", name: "" },
          },
        });
        setCustomerNameViaINN("");
      });
  };

  useEffect(() => {
    if (values?.contract?.id) {
      fetchContractByID(values?.contract?.id).then((res) => {
        setValues({ ...values, contract: res });
        setCustomerNameViaINN(res.customer.name);
      });
    }
  }, [values?.contract?.id]);

  return (
    <>
      <main className="workspace">
        <Sidebar />
        <div className="workspace__content">
          <section className="workspace__wrapper">
            <h3 className="title workspace__page-title">
              <span
                style={{
                  fontSize: "22px",
                  lineHeight: "1px",
                  marginRight: "10px",
                }}
              >
                &#10010;
              </span>
              {isEditPage ? "Редактировать" : "Добавить"} &nbsp;| Проект
            </h3>
            <form
              onSubmit={handleSubmit}
              className={`form ${displayErrors ? "form--isValid" : ""}`}
              style={{
                width: "1000px",
                justifyContent: "space-between",
                flexWrap: "wrap",
              }}
            >
              <section className="form__column" style={{ width: "300px" }}>
                <fieldset>
                  <InputWrapper>
                    <TextInput
                      label="ID договора"
                      type="text"
                      value={values?.contract?.id}
                      onChange={(e) => getContractByID(e.target.value)}
                      disabled={isEditPage}
                      labelHeight="24px"
                      alignLabel="left"
                      style={{
                        width: "120px",
                        marginRight: "20px",
                        marginTop: "0",
                      }}
                    />
                    <fieldset
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                      }}
                    >
                      <LabelTitle align="left">
                        Тип оценки:
                        <Asterisk />
                      </LabelTitle>
                      <div
                        style={{
                          display: "flex",
                        }}
                      >
                        <>
                          <input
                            id="sout"
                            className="radio"
                            type="radio"
                            name="assessmentTypeId"
                            value="WorkingConditionsSpecialAssesment"
                            checked={
                              values.assessmentTypeId ===
                              "WorkingConditionsSpecialAssesment"
                            }
                            onChange={(e) => inputChangeHandler(e)}
                            disabled={
                              accessRights[role].project.disabledFields
                                ?.assessmentType
                            }
                          />
                          <label
                            htmlFor="sout"
                            className="radio__label"
                            style={{
                              marginRight: "20px",
                              marginBottom: "0",
                            }}
                          >
                            СОУТ
                          </label>
                        </>
                        <>
                          <input
                            id="pc"
                            className="radio"
                            type="radio"
                            name="assessmentTypeId"
                            value="ProductionControl"
                            checked={
                              values.assessmentTypeId === "ProductionControl"
                            }
                            onChange={(e) => inputChangeHandler(e)}
                            disabled={
                              accessRights[role].project.disabledFields
                                ?.assessmentType
                            }
                          />
                          <label
                            htmlFor="pc"
                            className="radio__label"
                            style={{
                              marginRight: "20px",
                              marginBottom: "0",
                            }}
                          >
                            ПК
                          </label>
                        </>
                        <>
                          <input
                            style={{ margin: 0 }}
                            id="opr"
                            className="radio"
                            type="radio"
                            name="assessmentTypeId"
                            value="ProfessionalRisks"
                            checked={
                              values.assessmentTypeId === "ProfessionalRisks"
                            }
                            onChange={(e) => inputChangeHandler(e)}
                            disabled={
                              accessRights[role].project.disabledFields
                                ?.assessmentType
                            }
                          />
                          <label
                            htmlFor="opr"
                            className="radio__label"
                            style={{
                              marginRight: "20px",
                              marginBottom: "0",
                            }}
                          >
                            ОПР
                          </label>
                        </>
                      </div>
                    </fieldset>
                  </InputWrapper>

                  <label
                    className="workspace__input-title"
                    style={{ maxWidth: "380px", marginBottom: "20px" }}
                  >
                    <p>Наименование заказчика</p>
                    {isEditPage ? (
                      <p>{customerNameViaINN}</p>
                    ) : customerNameViaINN === undefined ? (
                      <p>Введите ID догвора...</p>
                    ) : isSearching ? (
                      <p>Поиск по базе данных...</p>
                    ) : customerNameViaINN ? (
                      <p>{customerNameViaINN}</p>
                    ) : (
                      <Text theme="red">Заказчик не найден!</Text>
                    )}
                  </label>
                  <label className="workspace__input-title">
                    <p>ИНН заказчика</p>
                    <p>{values?.contract?.customer?.inn}</p>
                  </label>
                  <label className="workspace__input-title">
                    Дополнительное наименование:
                  </label>
                  <input
                    className="input"
                    type="text"
                    name="name"
                    value={values.name}
                    onChange={(e) => inputChangeHandler(e)}
                    disabled={accessRights[role].project.disabledFields?.name}
                  />

                  <label className="workspace__input-title">
                    Контактные данные:
                  </label>
                  <textarea
                    className="textarea"
                    name="contactData"
                    value={values.contactData}
                    onChange={(e) => inputChangeHandler(e)}
                  />

                  <SelectRegions
                    required={true}
                    regions={values.Regions}
                    updateRegions={updateRegions}
                    disabled={
                      accessRights[role].project.disabledFields?.regions
                    }
                    isSearchBar={false}
                  />
                </fieldset>
              </section>
              <section
                className="form__column"
                style={{
                  minWidth: "350px",
                  maxWidth: "350px",
                  marginLeft: "0",
                }}
              >
                <Select
                  onChange={inputChangeHandler}
                  dataSrc="local"
                  label="Программа выполнения"
                  name="executionProgramId"
                  value={values.executionProgramId}
                  data={{
                    values: {
                      None: "Нет",
                      LaborExpert_3_0: "ТрудЭксперт",
                      Attestation_5_1: "Аттестация",
                    },
                  }}
                />
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <label
                    className="workspace__input-title"
                    style={{ width: "48%", display: "flex" }}
                  >
                    <div className="">
                      <LabelTitle>
                        Число РМ:
                        {!isNullRM && <Asterisk />}
                      </LabelTitle>
                      <input
                        className="input"
                        type="number"
                        required={!isNullRM}
                        disabled={
                          isNullRM ||
                          accessRights[role].project.disabledFields
                            ?.workspaceCount
                        }
                        name="workspaceCount"
                        value={values.workspaceCount}
                        onChange={(e) => inputChangeHandler(e)}
                        onWheel={(e) => e.target.blur()}
                      />
                    </div>
                    <label style={{ margin: "30px 0 0 5px", width: 50 }}>
                      <input
                        type="checkbox"
                        checked={isNullRM}
                        onClick={() => {
                          setIsNullRM(!isNullRM);
                          setValues({ ...values, workspaceCount: "" });
                        }}
                      />
                      <span
                        style={{
                          fontFamily: "monospace",
                          marginLeft: 5,
                          fontSize: 16,
                        }}
                      >
                        X
                      </span>
                    </label>
                  </label>

                  <fieldset
                    className="workspace__ensure-wrapper"
                    style={{ margin: "auto" }}
                  >
                    <label
                      className="workspace__input-ensure"
                      style={{ marginTop: 5 }}
                    >
                      <LabelTitle>Рабочая тетрадь:</LabelTitle>
                    </label>
                    <input
                      id="workbook-true"
                      className="radio"
                      type="radio"
                      name="haveWorkbook"
                      value="true"
                      checked={values.haveWorkbook === "true"}
                      onChange={(e) => inputChangeHandler(e)}
                      // disabled={true}
                    />
                    <label htmlFor="workbook-true" className="radio__label">
                      Да
                    </label>

                    <input
                      id="workbook-false"
                      className="radio"
                      type="radio"
                      name="haveWorkbook"
                      value="false"
                      defaultChecked
                      onChange={(e) => inputChangeHandler(e)}
                      // disabled={true}
                    />
                    <label
                      htmlFor="workbook-false"
                      className="radio__label workspace__label radio__label--ml"
                    >
                      Нет
                    </label>
                  </fieldset>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: "5px",
                  }}
                >
                  <label
                    className="workspace__input-title"
                    style={{ width: "48%" }}
                  >
                    <LabelTitle
                      styles={{
                        marginBottom: "10px",
                      }}
                    >
                      Плановая дата исполнения:
                      <Asterisk />
                    </LabelTitle>
                    <DatePicker
                      className="input"
                      name="plannedExecutionDate"
                      value={values?.plannedExecutionDate || null}
                      onChange={(date) =>
                        setValues({ ...values, plannedExecutionDate: date })
                      }
                      disabled={
                        accessRights[role].project.disabledFields
                          ?.plannedExecutionDate
                      }
                      required
                    />
                    <span className="error-message">
                      Укажите корректную дату
                    </span>
                  </label>
                  <label
                    className="workspace__input-title"
                    style={{ width: "48%" }}
                  >
                    <LabelTitle
                      styles={{
                        marginBottom: "10px",
                      }}
                    >
                      Дата получения информации:
                    </LabelTitle>
                    {/* <Asterisk /> */}
                    <DatePicker
                      className="input"
                      name="informationReceiptDate"
                      value={values?.informationReceiptDate || null}
                      onChange={(date) =>
                        setValues({ ...values, informationReceiptDate: date })
                      }
                    />
                    <span className="error-message">
                      Укажите корректную дату
                    </span>
                  </label>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: "5px",
                  }}
                >
                  <label
                    className="workspace__input-title"
                    style={{ width: "48%" }}
                  >
                    <LabelTitle
                      styles={{
                        marginBottom: "10px",
                      }}
                    >
                      Дата назначения исполнителя:
                    </LabelTitle>
                    {/* <Asterisk /> */}
                    <DatePicker
                      className="input"
                      name="performerAppointedDate"
                      value={values?.performerAppointedDate || null}
                      onChange={(date) =>
                        setValues({ ...values, performerAppointedDate: date })
                      }
                    />
                    <span className="error-message">
                      Укажите корректную дату
                    </span>
                  </label>
                  <label
                    className="workspace__input-title"
                    style={{ width: "48%" }}
                  >
                    <LabelTitle
                      styles={{
                        marginBottom: "10px",
                      }}
                    >
                      Дата приема в работу:
                    </LabelTitle>
                    {/* <Asterisk /> */}
                    <DatePicker
                      className="input"
                      name="acceptedToWorkDate"
                      value={values?.acceptedToWorkDate || null}
                      onChange={(date) =>
                        setValues({ ...values, acceptedToWorkDate: date })
                      }
                    />
                    <span className="error-message">
                      Укажите корректную дату
                    </span>
                  </label>
                </div>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: "5px",
                  }}
                >
                  <label
                    className="workspace__input-title"
                    style={{ width: "48%" }}
                  >
                    <LabelTitle
                      styles={{
                        marginBottom: "10px",
                      }}
                    >
                      Дата отправки на согласование:
                    </LabelTitle>
                    {/* <Asterisk /> */}
                    <DatePicker
                      className="input"
                      name="sendingForApprovalDate"
                      value={values?.sendingForApprovalDate || null}
                      onChange={(date) =>
                        setValues({ ...values, sendingForApprovalDate: date })
                      }
                    />
                    <span className="error-message">
                      Укажите корректную дату
                    </span>
                  </label>
                  <label
                    className="workspace__input-title"
                    style={{ width: "48%" }}
                  >
                    <LabelTitle
                      styles={{
                        marginBottom: "10px",
                      }}
                    >
                      Дата отправки отчета:
                    </LabelTitle>
                    {/* <Asterisk /> */}
                    <DatePicker
                      className="input"
                      name="reportSendingDate"
                      value={values?.reportSendingDate || null}
                      onChange={(date) =>
                        setValues({ ...values, reportSendingDate: date })
                      }
                    />
                    <span className="error-message">
                      Укажите корректную дату
                    </span>
                  </label>
                </div>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: "5px",
                  }}
                >
                  <label
                    className="workspace__input-title"
                    style={{ width: "48%" }}
                  >
                    <LabelTitle
                      styles={{
                        marginBottom: "10px",
                      }}
                    >
                      Дата подписания титульного листа:
                    </LabelTitle>
                    {/* <Asterisk /> */}
                    <DatePicker
                      className="input"
                      name="titleSheetSignDate"
                      value={values?.titleSheetSignDate || null}
                      onChange={(date) =>
                        setValues({ ...values, titleSheetSignDate: date })
                      }
                    />
                    <span className="error-message">
                      Укажите корректную дату
                    </span>
                  </label>
                  <label
                    className="workspace__input-title"
                    style={{ width: "48%" }}
                  >
                    <LabelTitle
                      styles={{
                        marginBottom: "10px",
                      }}
                    >
                      Дата выгрузки:
                    </LabelTitle>
                    {/* <Asterisk /> */}
                    <DatePicker
                      className="input"
                      name="unloadingDate"
                      value={values?.unloadingDate || null}
                      onChange={(date) =>
                        setValues({ ...values, unloadingDate: date })
                      }
                    />
                    <span className="error-message">
                      Укажите корректную дату
                    </span>
                  </label>
                </div>
              </section>
              <section
                className="form__column"
                style={{
                  minWidth: "270px",
                  maxWidth: "270px",
                  marginLeft: "0",
                }}
              >
                <label className="workspace__input-title">Организация:</label>
                <select
                  className="input search-area__select"
                  name="organizationId"
                  value={values.organizationId}
                  onChange={(e) => inputChangeHandler(e)}
                  disabled={
                    accessRights[role].project.disabledFields?.organizationId
                  }
                >
                  <option value={"null"}>-</option>
                  {organizations &&
                    organizations
                      .filter((item) => item.id !== LAB_DEFAULT_USER_ID)
                      .map(({ id, name }) => (
                        <option key={id} value={id}>
                          {" "}
                          {name}{" "}
                        </option>
                      ))}
                </select>
                <label className="workspace__input-title">Филиал:</label>
                <select
                  className="input search-area__select"
                  name="branchOfficeId"
                  value={values.branchOfficeId}
                  onChange={(e) => inputChangeHandler(e)}
                  disabled={
                    accessRights[role].project.disabledFields?.branchOfficeId
                  }
                >
                  <option value={"null"}>-</option>
                  {filials &&
                    filials.map(({ id, name }) => (
                      <option key={id} value={id}>
                        {" "}
                        {name}{" "}
                      </option>
                    ))}
                </select>
                <label className="workspace__input-title">Лаборатория:</label>
                <select
                  className="input search-area__select"
                  name="laboratoryUserId"
                  value={values.laboratoryUserId}
                  onChange={(e) => inputChangeHandler(e)}
                  disabled={
                    accessRights[role].project.disabledFields?.laboratoryUserId
                  }
                >
                  <option value={"null"}>-</option>
                  {laboratoryUsers &&
                    laboratoryUsers
                      .filter((item) => item.id !== LAB_DEFAULT_USER_ID)
                      .map(({ id, name }) => (
                        <option key={id} value={id}>
                          {" "}
                          {name}{" "}
                        </option>
                      ))}
                </select>
                {values?.assessmentTypeId ===
                  "WorkingConditionsSpecialAssesment" && (
                  <>
                    <label className="workspace__input-title">
                      Идентификационный номер:
                      <Asterisk />
                    </label>

                    <select
                      required={"true"}
                      className="input search-area__select"
                      name="contractDepartmentNumber"
                      value={values.contractDepartmentNumber}
                      onChange={(e) => inputChangeHandler(e)}
                      disabled={
                        accessRights[role].project.disabledFields
                          ?.contractDepartmentNumber
                      }
                    >
                      <option value={"null"}>-</option>
                      {values?.contract &&
                        values?.contract?.departments?.map((item) => (
                          <option
                            key={item?.departmentNumber}
                            value={item?.departmentNumber}
                          >
                            {item?.departmentNumber}
                          </option>
                        ))}
                    </select>
                  </>
                )}

                <label className="workspace__input-title">
                  Комментарий к исполнению:
                </label>
                <textarea
                  className="textarea"
                  cols="50"
                  rows="5"
                  name="executionDescription"
                  value={values.executionDescription}
                  onChange={(e) => inputChangeHandler(e)}
                  disabled={false}
                />
              </section>
              <div
                className="line"
                style={{
                  width: "1000px",
                  borderBottom: "2px solid #000",
                  margin: "25px 0 20px 0",
                  flexBasis: "100%",
                }}
              ></div>
              <div
                className="buttons"
                style={{
                  display: "flex",
                  maxWidth: "300px",
                  justifyContent: "space-between",
                  margin: "auto",
                  flexBasis: "100%",
                }}
              >
                <button
                  type="submit"
                  className="button button--orange"
                  onClick={() => setDisplayErrors(true)}
                >
                  {isEditPage ? "Сохранить" : "Добавить"}
                </button>
                <button
                  className="button button--orange"
                  onClick={(e) => {
                    e.preventDefault();
                    navigate(-1);
                  }}
                >
                  Отмена
                </button>
              </div>
            </form>
          </section>
        </div>
      </main>
    </>
  );
};
export default NewProjectPage;
