const colors = {
    RED: '#FF9393',
    YELLOW: '#F5FF7F',
    GREEN: '#68FF68',
    GRAY: '#D7D7D7'
}


export const getColor = (entity, stage, isExpired, isWarning) => {
    switch(entity){
        case "tender":
            return tenderColor(stage, isExpired, isWarning);
        case "contract":
            return contractColor(stage, isExpired, isWarning)
        case "project":
            return projectColor(stage, isExpired, isWarning)
        case "location":
            return locationColor(stage, isExpired, isWarning)
        default:
            console.log("Fetch error: incorrect entity name!"); 
    }

}

const tenderColor = (stage, isExpired, isWarning) => {
    let color;
    switch(stage){
        case ("Draft"):
        case("Estimated"):
        case("Approved"):
        case("AcceptedToWork"):
        case("Filed"):
        case("WonAndSigning"):
            if(isWarning)
                color = colors.YELLOW
            if(isExpired)
                color = colors.RED
            break;
        case ("Finish"): 
        case("Archive"):
            color = colors.GREEN
            break;        
        default:
            color = colors.GRAY
    }
    return color;
}

const contractColor = (stage, isExpired, isWarning) => {
    let color;
    switch(stage){
        case ("Draft"):
        case ("SigningAndCollectingInformation"):
            break;
        case ("IdAssigning"):
        case("InformationRequest"):
        case("LocationsCreation"):
        case("ProjectsCreation"):
        case("PreparationAndExecution"):
        case("Acts"):
        case("WaitingForUnloadingDocuments"):
            if(isWarning)
                color = colors.YELLOW
            if(isExpired)
                color = colors.RED
            break;
        case ("Finish"): 
        case("Archive"):
            color = colors.GREEN
            break;        
        default:
            color = colors.GRAY
    }
    return color;
}

const projectColor = (stage, isExpired, isWarning) => {
    let color;
    switch(stage){
        case ("OrganizationsAnalysis"):
        case("ExecutorsAnalysis"):
        case("ReadyToWork"):
        case("InformationRequest"):
        case("AcceptedToWork"):
        case("Agreement"):
        case("PrintedAndSent"):
        case("UnloadingDocuments"):
        case("Verification"):
        case("Errors"):
            if(isWarning)
                color = colors.YELLOW
            if(isExpired)
                color = colors.RED
            break;
        case ("Finish"): 
        case("Archive"):
            color = colors.GREEN
            break;        
        default:
            color = colors.GRAY
    }
    return color;
}

const locationColor = (stage, isExpired, isWarning) => {
    let color;
    switch(stage){
        case ("Unassigned"):
        case("Planned"):
        case("InLeaving"):
        case("Potential"):
            if(isWarning)
                color = colors.YELLOW
            if(isExpired)
                color = colors.RED
            break;
        case ("Finish"): 
        case("Archive"):
            color = colors.GREEN
            break;   
        case("Basket"):
        case("Suspended"):
            color = colors.GRAY
            break;      
        default:
            return ''
    }
    return color;
}
 