import { HiOutlineExternalLink } from "react-icons/hi";
import { toDateWithoutTime } from "../../../../../utils/formatDate";
import { toDateWithTime } from "../../../../../utils/formatDate";
import { translateText } from "../../../../../utils/stages";
import { useEffect } from "react";
import { fetchTenderById } from "utils/fetchTenderById";

const InfoTableModalTender = ({ setOpenModal, project }) => {
  useEffect(() => {
    fetchTenderById(project?.id).then((res) => console.log(res));
  });
  console.log(project);
  return (
    <section className="modal">
      <div className="modal__content">
        <h2
          className="modal__title"
          style={{ textAlign: "center", marginBottom: 20 }}
        >
          Информация о тендере
        </h2>
        <div className="flex-wrapper__modal__tables table-info">
          <div className="flex-wrapper__modal-column">
            <div>
              <h3 className="table__title">Общие сведения</h3>
              <table className="table__table-view-modal">
                <tbody>
                  <tr>
                    <td>ID тендера</td>
                    <td className="table__id">{project?.id || "-"}</td>
                  </tr>
                  <tr>
                    <td>Организатор</td>
                    <td>{project?.manager?.name || "-"}</td>
                  </tr>
                  <tr>
                    <td>ИНН организатора</td>
                    <td>{project?.manager?.inn || "-"}</td>
                  </tr>
                  <tr>
                    <td>Количество договоров</td>
                    <td>{project?.contractsCount || 0}</td>
                  </tr>
                  <tr>
                    <td>Этап тендера</td>
                    <td>
                      {project?.workFlow?.workFlowStatusId === "AcceptedToWork"
                        ? "Принято в работу по заявке"
                        : translateText(project?.workFlow?.workFlowStatusId) ||
                          "-"}
                    </td>
                  </tr>
                  <tr>
                    <td>Доп. наименование</td>
                    <td>{project?.customerAdditionalName || "-"}</td>
                  </tr>
                  <tr>
                    <td>Контактные данные</td>
                    <td>{project?.manager?.contactData || "-"}</td>
                  </tr>
                  <tr>
                    <td>Регионы</td>
                    <td>
                      {project?.regions?.length !== 0
                        ? project?.regions[0].name
                        : "-"}
                    </td>
                  </tr>
                  {/* <tr>
                    <td>Цена тендера</td>
                    <td>
                      {isNaN(
                        project?.contracts.reduce(
                          (acc, item) => (acc += item.price),
                          0
                        )
                      )
                        ? 0
                        : project?.contracts.reduce(
                            (acc, item) => (acc += item.price),
                            0
                          )}
                    </td> 
                  </tr> */}
                </tbody>
              </table>
            </div>
            <div>
              <h3 className="table__title">Ответственный</h3>
              <table className="table__table-view-modal">
                <tr>
                  <td>Тендерный отдел</td>
                  <td>
                    {project?.personInCharge?.name &&
                    project?.personInCharge?.lastName
                      ? project?.personInCharge?.name +
                        " " +
                        project?.personInCharge?.lastName
                      : "-"}
                  </td>
                </tr>
              </table>
            </div>
          </div>
          <div className="flex-wrapper__modal-column">
            <div>
              <h3 className="table__title">Тендерные сведения</h3>
              <table className="table__table-view-modal">
                <tbody>
                  <tr>
                    <td>ЕИС (ID на площадке)</td>
                    <td>{project?.eis || "-"}</td>
                  </tr>
                  <tr>
                    <td>Площадка</td>
                    <td>
                      {(
                        <a
                          href={project?.tradingFloor?.link}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {project?.tradingFloor?.name}
                        </a>
                      ) || "-"}
                    </td>
                  </tr>
                  <tr>
                    <td>Закон госзакупки</td>
                    <td>{translateText(project?.legalBasesId) || "-"}</td>
                  </tr>
                  <tr>
                    <td>Кто отыгрывает</td>
                    <td>
                      {project?.hasTradingRobot === true ? "Робот" : "Не робот"}
                    </td>
                  </tr>
                  <tr>
                    <td>Вид процедуры</td>
                    <td>{translateText(project?.operationTypeId) || "-"}</td>
                  </tr>
                  <tr>
                    <td>НМЦК</td>
                    <td>{project?.startMaxPrice}</td>
                  </tr>
                  <tr>
                    <td>Комментарий к заявке</td>
                    <td>{project?.description || "-"}</td>
                  </tr>
                  <tr>
                    <td>Комментарий о причине неподачи</td>
                    <td>{project?.failureDescription || "-"}</td>
                  </tr>
                  <tr>
                    <td>Победитель</td>
                    <td>{project?.winner?.name || "-"}</td>
                  </tr>
                  <tr>
                    <td>Цена победителя</td>
                    <td>{project?.winnerPrice || "-"}</td>
                  </tr>
                  <tr>
                    <td>Цена</td>
                    <td>{project?.price || "-"}</td>
                  </tr>
                  <tr>
                    <td>Наша цена</td>
                    <td>{project?.ourPrice || "-"}</td>
                  </tr>
                  <tr>
                    <td>Ссылка от агрегатора</td>
                    <td>
                      {project?.aggregatorLink ? (
                        <a
                          className="external-link"
                          href={project.aggregatorLink}
                          target="_blank"
                          rel="noreferrer noopener nofollow"
                        >
                          Перейти по ссылке
                          <HiOutlineExternalLink />
                        </a>
                      ) : (
                        " - "
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="flex-wrapper__modal-column">
            <div>
              <h3 className="table__title">Даты</h3>
              <table className="table__table-view-modal">
                <tr>
                  <td>Дата публикации</td>
                  <td>{toDateWithoutTime(project?.publicationDate) || "-"}</td>
                </tr>
                <tr>
                  <td>Дата окончания подачи заявки</td>
                  <td>
                    {toDateWithTime(project?.acceptingOffersDeadline) || "-"}
                  </td>
                </tr>
                <tr>
                  <td>Дата аукциона</td>
                  <td>{toDateWithTime(project?.auctionDate) || "-"}</td>
                </tr>
                <tr>
                  <td>Дата итогового протокола</td>
                  <td>
                    {toDateWithoutTime(project?.summaryReportDate) || "-"}
                  </td>
                </tr>
              </table>
            </div>
          </div>
          <div className="flex-wrapper__modal-row">
            <div>
              <table
                className="table__table-view-modal table-info"
                style={{ width: "100%", marginBottom: "20px" }}
              >
                <tbody>
                  <tr>
                    <td style={{ color: "darkviolet" }}>#</td>
                    <td style={{ color: "darkviolet" }}>ID договора</td>
                    <td style={{ color: "darkviolet" }}>Номер договора</td>
                    <td
                      style={{ color: "darkviolet", minWidth: 200, width: 450 }}
                    >
                      Заказчик
                    </td>
                    <td style={{ color: "darkviolet" }}>ИНН заказчика</td>
                    <td style={{ color: "darkviolet", minWidth: 100 }}>Цена</td>
                    <td style={{ color: "darkviolet" }}>
                      Дата заключения договора
                    </td>
                    <td style={{ color: "darkviolet" }}>
                      Плановая дата исполнения
                    </td>
                  </tr>
                  {project?.contracts?.map((item, index) => (
                    <tr>
                      <td>{index + 1}</td>
                      <td>{item?.id || "-"}</td>
                      <td>{item?.contractNumber || "-"}</td>
                      <td>{`${item?.customer?.name}`}</td>
                      <td>{item?.customer?.inn || "-"}</td>
                      <td>{item?.price || "-"}</td>
                      <td>
                        {toDateWithoutTime(item?.conclusionContractDate) || "-"}
                      </td>
                      <td>
                        {toDateWithoutTime(item?.plannedExecutionDate) || "-"}
                      </td>
                    </tr>
                  ))}
                  {project?.contracts?.length === 0 && (
                    <tr>
                      <td>{"-"}</td>
                      <td>{"-"}</td>
                      <td>{"-"}</td>
                      <td>{"-"}</td>
                      <td>{"-"}</td>
                      <td>{"-"}</td>
                      <td>{"-"}</td>
                      <td>{"-"}</td>
                    </tr>
                  )}
                  <tr>
                    <td colSpan={3}>
                      <p style={{ fontWeight: "bold" }}> Итого</p>
                    </td>
                    <td colSpan={2}></td>
                    <td>
                      {isNaN(
                        project?.contracts.reduce(
                          (acc, item) =>
                            (acc += isNaN(item?.price) ? 0 : item?.price),
                          0
                        )
                      )
                        ? 0
                        : project?.contracts.reduce(
                            (acc, item) =>
                              (acc += isNaN(item?.price) ? 0 : item?.price),
                            0
                          )}
                    </td>
                    <td colSpan={2}></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <button
          onClick={() => setOpenModal(false)}
          className="button button--purple close-button modal__close-button--bottom"
        >
          Закрыть окно
        </button>
      </div>
    </section>
  );
};
export default InfoTableModalTender;
