import GroupButton from "components/common/GroupButton";
import SelectRegions from "components/common/SelectRegions";
import { DatePicker } from "components/DateInput/DateInput";
import React, { useState, useEffect } from "react";
const LocationsCreationContractStage = ({
  mesBlocks,
  initCount,
  setMesBlocks,
  setIsDisabled,
  setIsVisible,
}) => {
  const [count, setCount] = useState(1);
  const [disable, setDisable] = useState(true);
  const [maxCount, setMaxCount] = useState(initCount || 1);
  const [measuringBlock, setMeasuringBlock] = useState({});
  const [arr, setArr] = useState([]);
  const [asses, setAsses] = useState([]);
  const [assesmentTypeArray, setAssesmentTypeArray] = useState([
    {
      value: "WorkingConditionsSpecialAssesment",
      label: "СОУТ",
      disabled: false,
    },
    { value: "ProfessionalRisks", label: "ОПР", disabled: false },
    { value: "ProductionControl", label: "ПК", disabled: false },
  ]);

  const handleCheckboxChange = (e) => {
    const { name, value, checked } = e.target;
    if (checked) {
      const newItem = {
        id: value,
      };
      setAsses(
        asses.includes(value)
          ? asses.filter((item) => item != value)
          : [...asses, newItem]
      );
    } else {
      const newItems = asses.filter((item) => item.id != value);
      setAsses(newItems);
    }
  };

  const updateRegions = (updatedRegions) => setArr(updatedRegions);

  useEffect(() => {
    setIsVisible(count == maxCount);
    setIsDisabled(mesBlocks.length != maxCount);
    if (count > maxCount && maxCount !== "") setMaxCount(Number(count));
    checkMeasuringBlock();
  });

  const nextMeasuringBlockHandler = () => {
    if (count != maxCount) setCount((prev) => Number(prev) + 1);
    setMesBlocks([
      ...mesBlocks,
      { ...measuringBlock, regions: arr, assessmentTypeList: asses },
    ]);
    setArr([]);
    setAsses([]);
    setMeasuringBlock({});
  };

  const validateNumber = (value) => {
    return value.replace(/[^0-9]/g, "");
  };

  const inputQtyHandler = (e) => {
    if (
      Number(e.target.value.replace(/[^0-9]/g, "")) >= count ||
      e.target.value === ""
    )
      setMaxCount(validateNumber(e.target.value));
  };

  const checkMeasuringBlock = () => {
    setDisable(
      !(
        arr.length !== 0 &&
        measuringBlock?.fromPlannedMeasurementsDate &&
        measuringBlock?.toPlannedMeasurementsDate
      )
    );
  };

  return (
    <div style={{ width: 700 }}>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div>
          <label className="modal__input-title">
            Количество замерных блоков:
          </label>
          <input
            className="input"
            type="text"
            value={maxCount}
            onBlur={() => {
              if (maxCount === "") setMaxCount(count);
            }}
            onChange={inputQtyHandler}
          />
        </div>
        <label style={{ marginRight: 280, marginTop: 30 }}>
          Без замеров*
          <input
            style={{ marginLeft: 5 }}
            type="checkbox"
            onChange={(e) => {
              if (e.target.checked == true) {
                setIsDisabled(false);
                setCount(0);
                setMaxCount(0);
              } else {
                setCount(1);
                setMaxCount(1);
              }
            }}
          />
        </label>

        <p className="measuringBlockModalCount">{`${Number(
          count
        )}/${maxCount}`}</p>
      </div>
      <div className="LocationsCreatModalContent">
        <div style={{ width: 250, marginTop: -25 }}>
          {/* <GroupButton
            entity={"locations"}
            onChangeHandler={handleCheckboxChange}
            type={"checkbox"}
            values={asses}
            title={"Тип оценки"}
            info={assesmentTypeArray}
          /> */}
          <SelectRegions regions={arr} updateRegions={updateRegions} />
        </div>
        <div className="dataPickLocationsCreation">
          <label className="locDate">С</label>
          <DatePicker
            className="input"
            name="fromPlannedMeasurementsDate"
            value={measuringBlock.fromPlannedMeasurementsDate || null}
            onChange={(date) =>
              setMeasuringBlock({
                ...measuringBlock,
                fromPlannedMeasurementsDate: date,
              })
            }
            required={true}
            min={new Date("01.01.2000")}
          />
          <label className="locDate">ПО</label>
          <DatePicker
            className="input"
            name="toPlannedMeasurementsDate"
            value={measuringBlock.toPlannedMeasurementsDate || null}
            onChange={(date) =>
              setMeasuringBlock({
                ...measuringBlock,
                toPlannedMeasurementsDate: date,
              })
            }
            required={true}
            min={new Date("01.01.2000")}
          />
        </div>
      </div>
      <button
        className={"button--olive button mesBtn"}
        onClick={nextMeasuringBlockHandler}
        disabled={disable}
      >
        {count == maxCount
          ? "Сoздать замерный блок"
          : "Перейти к следующему замерному блоку"}
      </button>
    </div>
  );
};

export default LocationsCreationContractStage;
