import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import Sidebar from "../../../components/Sidebar";
import { api } from "settings/Api";

import { departmentTypeKey } from "../../../utils/BaseUrl";
import { getBaseUrl } from "../../../utils/BaseUrl";
import { useNavigate } from "react-router-dom";
import { Select } from "../../../components/Select/Select";

const Edit = () => {
  const location = useLocation();
  const { user } = location.state;

  const navigate = useNavigate();

  const [id, setID] = useState("");
  const [name, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [patronymic, setPatronymic] = useState("");
  const [login, setLogin] = useState("");
  const [password, setPassword] = useState("");
  const [departmentId, setDepartment] = useState("");
  const [positionId, setPosition] = useState("");
  const [isActive, setIsActive] = useState("");
  const [email, setEmail] = useState("");
  const [currentFilialId, setCurrentFilialId] = useState(null);
  const [filial, setFilial] = useState(null);
  const [employess, setEmployees] = useState(null);
  const [currentOrganizationId, setCurrentOrganizationId] = useState(null);

  useEffect(() => {
    setID(user.state.id);
    setFirstName(user.state.name);
    setLastName(user.state.lastName);
    // setPatronymic(user.state.patronymic);
    setDepartment(user.state.departmentId);
    setPosition(user.state.positionId);
    setLogin(user.state.userName);
    setPassword(user.state.password || "");
    setIsActive(user.state.isActive);
    setEmail(user.state.email);
    setCurrentFilialId(user.state.branchOfficeId);
  }, []);

  console.log(user.state);

  const setData = async () => {
    const token = JSON.parse(localStorage.getItem("authTokens")).data
      .accessToken;
    const departmentType = localStorage.getItem(departmentTypeKey);

    api("/api/sout/admin/users", {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        id: id,
        name: name,
        lastName: lastName,
        userName: login,
        password: password,
        positionId: positionId,
        departmentId: departmentId,
        branchOfficeId: currentFilialId,
        organizationId: currentOrganizationId.id || currentOrganizationId,
        email: email,
        isActive: true,
      }),
    }).then((res) => {
      if (res.status === 200 || res.ok) {
        alert("Данные пользователя успешно обновлены!");
        navigate("/ListOfUsers");
      } else if (res.status === 500 || !res.ok) {
        alert("Ошибка. Данные пользователя не обновлены!");
      }
    });
  };

  const [currentEmp, setCurrentEmp] = useState(null);

  useEffect(() => {
    const fetchOrganizations = async (setEmployees) => {
      const token = JSON.parse(localStorage.getItem("authTokens")).data
        .accessToken;

      api("/api/sout/admin/organizations", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
        .then((res) => res.json())
        .then((res) => {
          const data = res;
          setEmployees(data.data);
        });
    };
    fetchOrganizations(setEmployees);
  }, []);

  useEffect(() => {
    const getUser = async (id) => {
      const token = JSON.parse(localStorage.getItem("authTokens")).data
        .accessToken;

      api(`/api/sout/admin/users/${id}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
        .then((res) => res.json())
        .then((res) => {
          const data = res;

          // setUserX(data.data);
        });
    };
    getUser(user.state.id);
  }, [user.state.id]);

  useEffect(() => {
    if (employess && user.state.organizationId) {
      const currentOrganisation = employess.find(
        (emp) => emp.id === user.state.organizationId
      );

      setCurrentOrganizationId(currentOrganisation?.id);
      // setCurrentEmp(currentOrganisation)
    }
  }, [employess && user.state.organizationId]);

  const onChange = (e) => {
    const value = e.target.value;
    setCurrentOrganizationId(value);
  };

  const findEmployeeName = () => {
    if (employess && currentOrganizationId) {
      const a = employess.find((emp) => emp.id === currentOrganizationId);

      return "KEK";
    } else {
      return "LOL";
    }
  };

  useEffect(() => {
    setFilial(
      employess?.find((i) => i.id === parseInt(currentOrganizationId))
        ?.branchOffices
    );
  }, [currentOrganizationId]);

  return (
    <main className="workspace">
      <Sidebar />
      <div className="workspace__content">
        <section className="workspace__wrapper">
          <h3 className="workspace__title workspace__page-title">
            Редактировать сотрудника
          </h3>
          <form
            style={{
              display: "flex",
              width: "900px",
              justifyContent: "space-between",
            }}
          >
            <div style={{ width: "32%" }}>
              <p className="workspace__input-title">Имя:</p>
              <input
                className="input"
                type="text"
                name="name"
                value={name}
                onChange={(e) => setFirstName(e.target.value)}
              />
              <p className="workspace__input-title">Фамилия</p>
              <input
                className="input"
                type="text"
                value={lastName}
                name="lastName"
                onChange={(e) => setLastName(e.target.value)}
              />
              <p className="workspace__input-title">Отдел:</p>
              <select
                className="input"
                value={departmentId}
                data-val="true"
                name="departmentId"
                onChange={(e) => setDepartment(e.target.value)}
              >
                <option defaultValue="selected">-</option>
                <option value="None">Нет</option>
                <option value="CommercialDepartment">Коммерческий отдел</option>
                <option value="TenderDepartment">Тендерный отдел</option>
                <option value="Laboratory">Лаборатория</option>
                <option value="AccountingDepartment">Бухгалтерия</option>
                {/* <option value="MedicalCenter">МЕД</option> */}
              </select>
            </div>

            <div style={{ width: "32%" }}>
              <p className="workspace__input-title">Должность:</p>
              <select
                value={positionId}
                className="input"
                name="positionId"
                onChange={(e) => setPosition(e.target.value)}
              >
                <option defaultValue="selected">-</option>
                <option value="None">Нет</option>
                <option value="Employee">Сотрудник</option>
                <option value="DepartmentHead">Начальник отдела</option>
                <option value="Expert">Эксперт</option>
                {departmentId === "Laboratory" && (
                  <>
                    <option value="Manager">Заведующий лабораторией</option>
                    <option value="Assistant">
                      Помощник заведующего лабораторией
                    </option>
                  </>
                )}
              </select>
              <Select
                value={currentOrganizationId}
                classNameLabel={"workspace__input-title"}
                onChange={onChange}
                label="Организация:"
                dataSrc="server"
                data={employess}
              />
              <Select
                classNameLabel={"workspace__input-title"}
                onChange={(e) => setCurrentFilialId(e.target.value)}
                label="Филиал:"
                dataSrc="server"
                value={currentFilialId}
                data={filial}
              />
            </div>
            <div style={{ width: "32%" }}>
              <p className="workspace__input-title">E-mail:</p>
              <input
                className="input"
                value={email}
                type="email"
                name="email"
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Введите логин"
              />
              <p className="workspace__input-title">Логин:</p>
              <input
                className="input"
                type="text"
                value={login}
                name="login"
                onChange={(e) => setLogin(e.target.value)}
                placeholder="Введите логин"
              />
              <p className="workspace__input-title">Пароль:</p>
              <input
                className="input"
                type="password"
                name="password"
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Введите пароль"
              />
            </div>
          </form>

          <div style={{ display: "flex" }}>
            <input
              type="button"
              onClick={setData}
              className="button staff__create-staff-button"
              value="Сохранить"
            />
            <button
              style={{ marginLeft: 20 }}
              type="button"
              onClick={() => navigate("/ListOfUsers")}
              className="button staff__create-staff-button"
            >
              Отмена
            </button>
          </div>
        </section>
      </div>
    </main>
  );
};
export default Edit;
