import { SidebarList } from "components/SidebarList";
import React from "react";
import { FaBars, FaSearch } from "react-icons/fa";
import {
  isAccountantUser,
  isAdmin,
  isCommercialUser,
  isLabEmployee,
  isLabExpert,
  isLabHead,
  isLabHeadDeputy,
  isTenderUser,
} from "utils/defineUser";
import { ReactComponent as InWorkIcon } from "assets/images/InWork.svg";

const MAIN_LIST = [
  {
    link: "/sout/projects/global-search",
    content: (
      <>
        <FaSearch className="sidebar__icon-search" />{" "}
        <b className="sidebar__search-span">Общий поиск</b>
      </>
    ),
    type: "default",
  },
  {
    link: "/sout/projects/actual",
    content: (
      <>
        <FaBars className="sidebar__icon-search" />{" "}
        <b className="sidebar__search-span">Все актуальные </b>
      </>
    ),
    type: "default",
  },
  {
    link: "/sout/projects/organizations-analysis",

    content: "Первичный разбор",
    type: "default",
  },
  {
    link: "/sout/projects/ready-to-work",
    content: "Готовые к работе",
    type: "default",
  },
  {
    link: "/sout/projects/information-collection",
    content: "Сбор информации",
    type: "default",
  },
  {
    link: "/sout/projects/accepted-to-work",
    content: "Принято в работу",
    type: "default",
  },
  {
    link: "/sout/projects/agreement",
    content: "Согласование",
    type: "default",
  },
  {
    link: "/sout/projects/printed-and-sent",
    content: "Распечатан и отправлен",
    type: "default",
  },
  {
    link: "/sout/projects/verification",
    content: "Проверка",
    type: "default",
  },
  {
    link: "/sout/projects/unloading-documents",
    content: "Выгрузка",
    type: "default",
  },
  {
    link: "/sout/projects/finish",
    content: "Завершенные",
    type: "default",
  },
  {
    link: "/sout/projects/archive",
    content: "Архив",
    type: "archive",
  },
  {
    link: "/sout/projects/suspended",
    content: "Приостановленные",
    type: "no-result-space",
  },
  {
    link: "/sout/projects/basket",
    content: "Корзина",
    type: "no-result-space",
  },
  {
    link: "/sout/projects/errors",
    content: "Ошибки",
    type: "no-result",
  },
];

const ProjectSidebar = ({ toggleSidebar, isOpen }) => {
  return (
    <>
      {isTenderUser() || isAdmin() ? (
        <SidebarList
          title="Коммерческий отдел"
          titleIsOpen={isOpen.commercial}
          toggleSidebar={() => toggleSidebar("commercial")}
          color="orange"
          items={[
            {
              type: "line",
            },
          ]}
        />
      ) : null}
      {isCommercialUser() || isAdmin() ? (
        <SidebarList
          title="Тендерный отдел"
          titleIsOpen={isOpen.tender}
          toggleSidebar={() => toggleSidebar("tender")}
          color="orange"
          items={[
            {
              type: "line",
            },
          ]}
        />
      ) : null}
      {isLabHead() || isAdmin() ? (
        <SidebarList
          title="Начальник лаборатории"
          titleIsOpen={isOpen.lab_chief}
          toggleSidebar={() => toggleSidebar("lab_chief")}
          color="orange"
          items={[
            {
              link: "/sout/projects/global-search",
              content: (
                <>
                  <FaSearch className="sidebar__icon-search" />{" "}
                  <b className="sidebar__search-span">Общий поиск</b>
                </>
              ),
              type: "default",
            },
            {
              link: "/sout/projects/actual",
              content: (
                <>
                  <FaBars className="sidebar__icon-search" />{" "}
                  <b className="sidebar__search-span">Все актуальные </b>
                </>
              ),
              type: "default",
            },
            {
              link: "/sout/projects/organizations-analysis",

              content: "Первичный разбор",
              type: "default",
            },
            {
              link: "/sout/projects/executots-analysis",

              content: "Разбор по исполнителям",
              type: "default",
            },
            {
              link: "/sout/projects/information-collection",
              content: "Сбор информации",
              type: "default",
            },
            {
              link: "/sout/projects/ready-to-work",
              content: "Готовые к работе",
              type: "default",
            },
            {
              link: "/sout/projects/accepted-to-work",
              content: "Принято в работу",
              type: "default",
            },
            {
              link: "/sout/projects/agreement",
              content: "Согласование",
              type: "default",
            },
            {
              link: "/sout/projects/printed-and-sent",
              content: "Распечатан и отправлен",
              type: "default",
            },
            {
              link: "/sout/projects/verification",
              content: "Проверка",
              type: "default",
            },
            {
              link: "/sout/projects/unloading-documents",
              content: "Выгрузка",
              type: "default",
            },
            {
              link: "/sout/projects/finish",
              content: "Завершенные",
              type: "default",
            },
            {
              link: "/sout/projects/archive",
              content: "Архив",
              type: "archive",
            },
            {
              link: "/sout/projects/basket",
              content: "Корзина",
              type: "no-result-space",
            },
            {
              link: "/sout/projects/suspended",
              content: "Приостановленные",
              type: "no-result",
            },
            {
              link: "/sout/projects/errors",
              content: "Ошибки",
              type: "no-result",
            },
          ]}
        />
      ) : null}
      {isLabHeadDeputy() || isAdmin() ? (
        <SidebarList
          title="Заведующий лабораторией"
          titleIsOpen={isOpen.lab_head_deputy}
          toggleSidebar={() => toggleSidebar("lab_head_deputy")}
          color="orange"
          items={[
            {
              link: "/sout/projects/global-search",
              content: (
                <>
                  <FaSearch className="sidebar__icon-search" />{" "}
                  <b className="sidebar__search-span">Общий поиск</b>
                </>
              ),
              type: "default",
            },
            {
              link: "/sout/projects/actual",
              content: (
                <>
                  <FaBars className="sidebar__icon-search" />{" "}
                  <b className="sidebar__search-span">Все актуальные </b>
                </>
              ),
              type: "default",
            },
            {
              link: "/sout/projects/organizations-analysis",

              content: "Первичный разбор",
              type: "default",
            },
            {
              link: "/sout/projects/executots-analysis",

              content: "Разбор по исполнителям",
              type: "default",
            },
            {
              link: "/sout/projects/information-collection",
              content: "Сбор информации",
              type: "default",
            },
            {
              link: "/sout/projects/ready-to-work",
              content: "Готовые к работе",
              type: "default",
            },
            {
              link: "/sout/projects/accepted-to-work",
              content: "Принято в работу",
              type: "default",
            },
            {
              link: "/sout/projects/agreement",
              content: "Согласование",
              type: "default",
            },
            {
              link: "/sout/projects/printed-and-sent",
              content: "Распечатан и отправлен",
              type: "default",
            },
            {
              link: "/sout/projects/verification",
              content: "Проверка",
              type: "default",
            },
            {
              link: "/sout/projects/unloading-documents",
              content: "Выгрузка",
              type: "default",
            },
            {
              link: "/sout/projects/finish",
              content: "Завершенные",
              type: "default",
            },
            {
              link: "/sout/projects/archive",
              content: "Архив",
              type: "archive",
            },
            {
              link: "/sout/projects/basket",
              content: "Корзина",
              type: "no-result-space",
            },
            {
              link: "/sout/projects/suspended",
              content: "Приостановленные",
              type: "no-result",
            },
            {
              link: "/sout/projects/errors",
              content: "Ошибки",
              type: "no-result",
            },
          ]}
        />
      ) : null}

      {isLabExpert() || isAdmin() ? (
        <SidebarList
          title="Эксперт лаборатории"
          titleIsOpen={isOpen.lab_expert}
          toggleSidebar={() => toggleSidebar("lab_expert")}
          color="orange"
          items={[
            {
              link: "/sout/projects/global-search",
              content: (
                <>
                  <FaSearch className="sidebar__icon-search" />{" "}
                  <b className="sidebar__search-span">Общий поиск</b>
                </>
              ),
              type: "default",
            },
            {
              link: "/sout/projects/actual",
              content: (
                <>
                  <FaBars className="sidebar__icon-search" />{" "}
                  <b className="sidebar__search-span">Все актуальные </b>
                </>
              ),
              type: "default",
            },
            {
              link: "/sout/projects/organizations-analysis",

              content: "Первичный разбор",
              type: "default",
            },
            {
              link: "/sout/projects/executots-analysis",

              content: "Разбор по исполнителям",
              type: "default",
            },
            {
              link: "/sout/projects/information-collection",
              content: "Сбор информации",
              type: "default",
            },
            {
              link: "/sout/projects/ready-to-work",
              content: "Готовые к работе",
              type: "default",
            },
            {
              link: "/sout/projects/accepted-to-work",
              content: "Принято в работу",
              type: "default",
            },
            {
              link: "/sout/projects/agreement",
              content: "Согласование",
              type: "default",
            },
            {
              link: "/sout/projects/printed-and-sent",
              content: "Распечатан и отправлен",
              type: "default",
            },
            {
              link: "/sout/projects/verification",
              content: "Проверка",
              type: "default",
            },
            {
              link: "/sout/projects/unloading-documents",
              content: "Выгрузка",
              type: "default",
            },
            {
              link: "/sout/projects/finish",
              content: "Завершенные",
              type: "default",
            },
            {
              link: "/sout/projects/archive",
              content: "Архив",
              type: "archive",
            },
            {
              link: "/sout/projects/basket",
              content: "Корзина",
              type: "no-result-space",
            },
            {
              link: "/sout/projects/suspended",
              content: "Приостановленные",
              type: "no-result",
            },
            {
              link: "/sout/projects/errors",
              content: "Ошибки",
              type: "no-result",
            },
          ]}
        />
      ) : null}
      {isLabEmployee() || isAdmin() ? (
        <SidebarList
          title="Лаборатория"
          titleIsOpen={isOpen.lab_assist}
          toggleSidebar={() => toggleSidebar("lab_assist")}
          color="orange"
          items={[
            {
              link: "/sout/projects/information-collection",
              content: "Сбор информации",
              type: "default",
            },
            {
              link: "/sout/projects/ready-to-work",
              content: "Готовые к работе",
              type: "default",
            },
            {
              link: "/sout/projects/accepted-to-work",
              content: "Принято в работу",
              type: "default",
            },
            {
              link: "/sout/projects/agreement",
              content: "Согласование",
              type: "default",
            },
            {
              link: "/sout/projects/printed-and-sent",
              content: "Распечатан и отправлен",
              type: "default",
            },
            {
              link: "/sout/projects/verification",
              content: "Проверка",
              type: "default",
            },
            {
              link: "/sout/projects/unloading-documents",
              content: "Выгрузка",
              type: "default",
            },
            {
              link: "/sout/projects/finish",
              content: "Завершенные",
              type: "default",
            },
            {
              link: "/sout/projects/archive",
              content: "Архив",
              type: "archive",
            },
            {
              link: "/sout/projects/basket",
              content: "Корзина",
              type: "no-result-space",
            },
            {
              link: "/sout/projects/suspended",
              content: "Приостановленные",
              type: "no-result",
            },
            {
              link: "/sout/projects/errors",
              content: "Ошибки",
              type: "no-result",
            },
          ]}
        />
      ) : null}
      {isAccountantUser() || isAdmin() ? (
        <SidebarList
          title="Бухгалтерия"
          titleIsOpen={isOpen.accountant}
          toggleSidebar={() => toggleSidebar("accountant")}
          color="orange"
          items={[
            {
              type: "line",
            },
          ]}
        />
      ) : null}
      {isAdmin() ? (
        <SidebarList
          title="Админ"
          titleIsOpen={isOpen.admin}
          toggleSidebar={() => toggleSidebar("admin")}
          color="orange"
          items={[
            {
              link: "/sout/projects/global-search",
              content: (
                <>
                  <FaSearch className="sidebar__icon-search" />{" "}
                  <b className="sidebar__search-span">Общий поиск</b>
                </>
              ),
              type: "default",
            },
            {
              link: "/sout/projects/actual",
              content: (
                <>
                  <FaBars className="sidebar__icon-search" />{" "}
                  <b className="sidebar__search-span">Все актуальные </b>
                </>
              ),
              type: "default",
            },
            {
              link: "/sout/projects/organizations-analysis",

              content: "Первичный разбор",
              type: "default",
            },
            {
              link: "/sout/projects/executots-analysis",

              content: "Разбор по исполнителям",
              type: "default",
            },
            {
              link: "/sout/projects/information-collection",
              content: "Сбор информации",
              type: "default",
            },
            {
              link: "/sout/projects/ready-to-work",
              content: "Готовые к работе",
              type: "default",
            },
            {
              link: "/sout/projects/accepted-to-work",
              content: "Принято в работу",
              type: "default",
            },
            {
              link: "/sout/projects/agreement",
              content: "Согласование",
              type: "default",
            },
            {
              link: "/sout/projects/printed-and-sent",
              content: "Распечатан и отправлен",
              type: "default",
            },
            {
              link: "/sout/projects/verification",
              content: "Проверка",
              type: "default",
            },
            {
              link: "/sout/projects/unloading-documents",
              content: "Выгрузка",
              type: "default",
            },
            {
              link: "/sout/projects/finish",
              content: "Завершенные",
              type: "default",
            },
            {
              link: "/sout/projects/archive",
              content: "Архив",
              type: "archive",
            },
            {
              link: "/sout/projects/terminated",
              content: "Расcторгнутые",
              type: "no-result-space",
            },
            {
              link: "/sout/projects/suspended",
              content: "Приостановленные",
              type: "no-result",
            },
            {
              link: "/sout/projects/errors",
              content: "Ошибки",
              type: "no-result",
            },
          ]}
        />
      ) : null}
    </>
  );
};

export default ProjectSidebar;
