import { useCallback, useEffect, useMemo, useState } from "react";

const typeToText = {
  tender: "тендеров",
  contract: "договоров",
  project: "проектов",
  location: "замерных блоков",
};

export const Pagination = (props) => {
  const {
    currentPage,
    totalPagesCount,
    toStart,
    toEnd,
    forward,
    back,
    change,
    totalRowCount,
    records,
    color,
    type,
  } = props;

  const [paginationNumbers, setPaginationNumbers] = useState([]);

  const toStartPage = () => {
    const paginationArray = generatePaginationArray(parseInt(1));
    setPaginationNumbers(paginationArray);
    toStart();
  };

  const toEndPage = () => {
    const paginationArray = generatePaginationArray(parseInt(totalPagesCount));
    setPaginationNumbers(paginationArray);
    toEnd();
  };

  const forwardPage = () => {
    const paginationArray = generatePaginationArray(parseInt(currentPage + 1));
    setPaginationNumbers(paginationArray);
    forward();
  };

  const backPage = () => {
    const paginationArray = generatePaginationArray(parseInt(currentPage - 1));
    setPaginationNumbers(paginationArray);
    back();
  };

  const changePage = (to) => {
    if (to >= 1 && to <= totalPagesCount) {
      const paginationArray = generatePaginationArray(to);
      setPaginationNumbers(paginationArray);
      change(to);
    }
  };

  const PAGINATION_SIZE = 5;

  const generatePaginationArray = useCallback(
    (currentPage) => {
      if (Number.isNaN(currentPage)) return paginationNumbers;

      const range = Math.floor(PAGINATION_SIZE / 2);
      let firstNumber, lastNumber;

      firstNumber = currentPage - range - 1 <= 0 ? 1 : currentPage - range - 1;

      if (currentPage === PAGINATION_SIZE) firstNumber = 1;

      if (currentPage <= PAGINATION_SIZE) {
        lastNumber =
          totalPagesCount <= PAGINATION_SIZE
            ? totalPagesCount
            : PAGINATION_SIZE;
      }

      if (currentPage > PAGINATION_SIZE) {
        firstNumber = currentPage - range;
        lastNumber = currentPage + range;
      }

      //If pagination ending [..., 999]
      if (
        totalPagesCount > PAGINATION_SIZE &&
        currentPage >= totalPagesCount - range
      ) {
        firstNumber = totalPagesCount - PAGINATION_SIZE;
        lastNumber = totalPagesCount;
      }

      const paginationArray = [];
      for (let i = firstNumber; i <= lastNumber; i++) {
        paginationArray.push(i);
      }

      return paginationArray;
    },
    [paginationNumbers, totalPagesCount]
  );

  useEffect(() => {
    const paginationArray = generatePaginationArray(currentPage);
    setPaginationNumbers(paginationArray);
  }, []);

  return (
    <ul className="pagination contracts__pagination">
      <div className="pagination__pages-container contracts__pagination">
        <li className={`pagination__item ${color}`}>
          Страница{" "}
          <input
            type="number"
            className={`input pagination__input ${color}-input`}
            name="activePage"
            value={currentPage}
            min={1}
            max={totalPagesCount}
            onChange={(e) => changePage(parseInt(e.target.value))}
          />
          /<span className="pagination__page-counter">{totalPagesCount}</span>
        </li>
        <li className={`pagination__item ${color}`}>
          <button
            onClick={toStartPage}
            className={`pagination__link pagination__elem pagination-button ${color}-input ${color}-link`}
            style={{
              fontWeight: "bold",
              fontSize: "18px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            &#171;
          </button>
        </li>
        <li className={`pagination__item ${color}`}>
          <button
            onClick={backPage}
            className={`pagination__link pagination__elem pagination-button ${color}-input ${color}-link`}
            style={{
              fontWeight: "bold",
              fontSize: "18px",

              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            disabled={currentPage <= 1}
          >
            &lt;
          </button>
        </li>
        {paginationNumbers &&
          paginationNumbers?.map((pagNum) => (
            <li key={pagNum} className={`pagination__item ${color}`}>
              <button
                onClick={() => changePage(parseInt(pagNum))}
                className={`${color}-input ${
                  currentPage === pagNum
                    ? `pagination--active ${color}-linkActive`
                    : null
                } pagination__link pagination__elem pagination-button ${color}-link`}
                value={pagNum}
                style={{ color: currentPage === pagNum ? "white" : "" }}
              >
                {pagNum}
              </button>
            </li>
          ))}
        <li className={`pagination__item ${color}`}>
          <button
            onClick={forwardPage}
            className={`pagination__link pagination__elem pagination-button ${color}-input ${color}-link`}
            style={{
              fontWeight: "bold",
              fontSize: "18px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            disabled={currentPage >= totalPagesCount}
          >
            &gt;
          </button>
        </li>
        <li className={`pagination__item ${color}`}>
          <button
            onClick={toEndPage}
            className={`pagination__link pagination__elem pagination-button ${color}-input ${color}-link`}
            style={{
              fontWeight: "bold",
              fontSize: "18px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            &#187;
          </button>
        </li>
        <li
          className={`pagination__item ${color}  pagination__total-row-count`}
        >
          Всего {typeToText[type]}: <span>{totalRowCount}</span>
        </li>
      </div>
    </ul>
  );
};
