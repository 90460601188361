import { DatePicker } from "components/DateInput/DateInput";
import React, { useEffect, useState } from "react";
import fetchUsers from "utils/fetchUsers";

const OrganizationAnalysisProjectStage = ({
  competitor,
  filial,
  setCompetitor,
  move,
  setDate,
  date,
  setIsDisabled,
}) => {
  const [competitors, setCompetitors] = useState([]);

  useEffect(() => {
    setIsDisabled(!(competitor && date));
  });

  useEffect(() => {
    fetchUsers("Laboratory", null, null, setCompetitors, filial);
  }, []);

  return (
    <div
      className="modal__content-center"
      style={{
        maxHeight: "1000px",
      }}
    >
      <label className="modal__input-title" style={{ width: 300 }}>
        Назначение исполнителя
      </label>
      <select
        className="input search-area__select"
        name="winner"
        value={competitor?.id}
        onChange={(e) =>
          setCompetitor(competitors.find((item) => item.id == e.target.value))
        }
        style={{ width: 300, margin: "0 auto" }}
      >
        <option value="null">-</option>
        {competitors &&
          competitors?.map(({ id, name }) => (
            <option key={id} value={id}>
              {" "}
              {name}{" "}
            </option>
          ))}
      </select>
      <div style={{ width: 300, marginTop: 15 }}>
        <label className="modal__input-title">Дата назначения</label>
        <DatePicker
          className="input"
          name="plannedExecutionDate"
          value={date}
          onChange={(date) => setDate(date)}
          required={true}
          min={new Date("01.01.2000")}
        />
      </div>
    </div>
  );
};

export default OrganizationAnalysisProjectStage;
