import { useContext, useEffect } from "react";
import ProjectContext from "../../context/ProjectsContext";
import { useNavigate } from "react-router-dom";
import { api } from "settings/Api";

import { useState } from "react";
import ProjectsTable from "./ProjectsTable";
import { generateToken } from "shared/generateToken";
import Spinner from "../Spinner";
import { useParams } from "react-router-dom";

import { isCommercialEmployee, isLabEmployee } from "../../utils/defineUser";
import AuthContext from "../../context/AuthContext";
import { useLocation } from "react-router-dom";
import ProjectsTableTender from "./ProjectsTableTender";
import { generateQueriesForSearchRequestWithId } from "shared/generateQueriesForSearchRequestWithId";
import { getIsCompareData } from "shared/getIsCompareData";
import { removeIsCompareData } from "shared/removeIsCompareData";

const StageTender = () => {
  const location = useLocation();

  const params = useParams();

  const { departmentId } = params;

  const navigate = useNavigate();

  const {
    isProjectMoved,
    queryStrings,
    setQueryStrings,
    paginationQuery,
    setPaginationQuery,
    setPaginationParams,
    setCurrentPageNumber,
    isProjectsLoaded,
    setIsUpdateDraftTender,
    setIsProjectsLoaded,
    setStageTitle,
    isUdpateDraftTender,
    submitButtonPressed,
    account,
    setAccount,
    setSubmitButtonPressed,
    setAdditionalInfoValue,
    currentPageNumber,
    setValues,
    values,
    setSortParam,
  } = useContext(ProjectContext);

  const { updateToken, is } = useContext(AuthContext);

  const [projects, setProjects] = useState("");

  if (location?.state) {
    const { entity_type } = location.state;
    localStorage.setItem("__entity-type", entity_type);
  }
  //TODO: Into separate file - options.js
  const WORKSPACE_OPTIONS = {
    sout: {
      tender: {
        "global-search": ["Общий поиск", ""],
        actual: [
          "Все актуальные",
          "WorkFlowStatuses=Draft&WorkFlowStatuses=Estimated&WorkFlowStatuses=Approved&WorkFlowStatuses=AcceptedToWork&WorkFlowStatuses=Filed&WorkFlowStatuses=WonAndSigning&WorkFlowStatuses=Finish",
        ],
        draft: ["Черновик", "WorkFlowStatuses=Draft"],
        estimated: ["Оцененные", "WorkFlowStatuses=Estimated"],
        approved: ["Одобренные", "WorkFlowStatuses=Approved"],
        "accepted-to-work": [
          "Принято в работу по заявке",
          "WorkFlowStatuses=AcceptedToWork",
        ],
        filed: ["Поданные", "WorkFlowStatuses=Filed"],
        "won-and-signing": [
          "Победа и подписание",
          "WorkFlowStatuses=WonAndSigning",
        ],
        "sent-to-tender": [
          "Отправлено в тендерный отдел",
          "WorkFlowStatuses=AcceptedToWork&WorkFlowStatuses=Filed&WorkFlowStatuses=WonAndSigning&WorkFlowStatuses=Finish",
        ],
        finish: ["Завершенные", "WorkFlowStatuses=Finish"],
        archive: ["Архив", "WorkFlowStatuses=Archive"],
        basket: ["Корзина", "WorkFlowStatuses=Basket"],
        "not-filed": ["Не поданные", "WorkFlowStatuses=NotFiled"],
        lost: ["Не выигранные", "WorkFlowStatuses=Lost"],
        lostHasNoWinner: [
          "Победитель неизвестен",
          "WorkFlowStatuses=Lost&HasWinner=false",
        ],
        lostHasWinner: [
          "Победитель известен",
          "WorkFlowStatuses=Lost&HasWinner=true",
        ],
        "not-signed": ["Не подписанные", "WorkFlowStatuses=NotSigned"],
      },
      projects: {
        "global-search": ["Общий поиск", "null=null"],
        actual: [
          "Все актуальные",
          "WorkFlowStatuses=ReadyToWork&WorkFlowStatuses=OrganizationsAnalysis&WorkFlowStatuses=InformationRequest&WorkFlowStatuses=AcceptedToWork&WorkFlowStatuses=Agreement&WorkFlowStatuses=PrintedAndSent&WorkFlowStatuses=UnloadingDocuments&WorkFlowStatuses=ExecutorsAnalysis&WorkFlowStatuses=Finish&WorkFlowStatuses=verification",
        ],
        "at-work": [
          "В работе",
          "WorkFlowStatuses=InformationRequest&WorkFlowStatuses=AcceptedToWork&WorkFlowStatuses=Agreement",
        ],
        "organizations-analysis": [
          "Первичный разбор",
          "WorkFlowStatuses=OrganizationsAnalysis",
        ],
        "ready-to-work": ["Готовые к работе", "WorkFlowStatuses=ReadyToWork"],
        "executots-analysis": [
          "Разбор по исполнителям",
          "WorkFlowStatuses=ExecutorsAnalysis",
        ],
        "information-collection": [
          "Сбор информации",
          "WorkFlowStatuses=InformationRequest",
        ],
        basket: ["Корзина", "WorkFlowStatuses=Basket"],
        "accepted-to-work": [
          "Принято в работу",
          "WorkFlowStatuses=AcceptedToWork",
        ],
        agreement: ["Согласование", "WorkFlowStatuses=Agreement"],
        "printed-and-sent": [
          "Распечатан и отправлен",
          "WorkFlowStatuses=PrintedAndSent",
        ],
        "unloading-documents": [
          "Выгрузка",
          "WorkFlowStatuses=UnloadingDocuments",
        ],
        verification: ["Проверка", "WorkFlowStatuses=Verification"],
        errors: ["Ошибки", "WorkFlowStatuses=Errors"],
        finish: ["Завершенные", "WorkFlowStatuses=Finish"],
        archive: ["Архив", "WorkFlowStatuses=Archive"],
        suspended: ["Приостановленные", "WorkFlowStatuses=Suspended"],
        terminated: ["Рассторгнутые", "WorkFlowStatuses=Terminated"],
        "not-filed": ["Приостановленные", "WorkFlowStatuses=Terminated"],
      },
    },
  };

  if (!localStorage.getItem("__user-department") && params.departmentId) {
    localStorage.setItem("__user-department", params.departmentId);
  }

  const getStageTitle = () => {
    if (params.directionId === "undefined") {
      navigate("/Home");
      return;
    }

    const title =
      WORKSPACE_OPTIONS[params.directionId][params.departmentId][
        params.stageId
      ][0];
    setStageTitle(title);
    return title;
  };

  const getStageQuery = () => {
    if (params.directionId === "undefined") {
      navigate("/Home");
      return;
    }

    return WORKSPACE_OPTIONS[params.directionId][params.departmentId][
      params.stageId
    ][1];
  };

  const PAGE_ROW_COUNT = 20;

  useEffect(() => {
    setQueryStrings("");

    setAdditionalInfoValue("");
  }, []);

  useEffect(() => {
    setPaginationQuery(
      `&PageRowCount=${
        account?.organizationId === 1 ? 20 : 2500
      }&Page=${currentPageNumber}`
    );
  }, [departmentId, account]);

  useEffect(() => {
    let getActualStage = localStorage.getItem("__stageId");

    if (!getActualStage) {
      localStorage.setItem("__stageId", params.stageId);
    }

    if (getActualStage !== params.stageId) {
      // if (params.stageId !== "draft") setQueryStrings("");
      // setPaginationQuery(`&PageRowCount=${PAGE_ROW_COUNT}&Page=1`);
      setCurrentPageNumber(1);
      //Actualize current stage
      localStorage.setItem("__stageId", params.stageId);
    }
    setIsUpdateDraftTender(false);
  }, [
    params,
    isProjectMoved,
    isUdpateDraftTender,
    queryStrings,
    paginationQuery,
  ]);
  const isCompareData = getIsCompareData();
  const fetchProjects = async () => {
    setIsProjectsLoaded(false);
    const token = generateToken();
    let baseUrl = "";

    if (departmentId === "tender") {
      baseUrl = "/api/sout/admin/tenders?";
    }

    if (departmentId === "projects") {
      baseUrl = "/api/sout/admin/separated-projects?";
    }

    let fetchUrl = "";
    let queryParams = "";

    if (isCompareData) {
      setValues({ id: isCompareData.id });
      queryParams = isCompareData.id ? `&id=${isCompareData.id}&` : "&";
    } else {
      queryParams = queryStrings;
    }

    removeIsCompareData();
    if (isLabEmployee()) {
      fetchUrl =
        baseUrl +
        getStageQuery() +
        queryParams +
        paginationQuery +
        `&LaboratoryDepartmentEmployeeId=${localStorage.getItem("__user-id")}`;
    } else if (isCommercialEmployee()) {
      fetchUrl =
        baseUrl +
        getStageQuery() +
        queryParams +
        paginationQuery +
        `&CommercialDepartmentEmployeeId=${localStorage.getItem("__user-id")}`;
    } else {
      fetchUrl = baseUrl + getStageQuery() + queryParams + paginationQuery;
    }
    const response = await api(fetchUrl, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    let data;

    if (response.status === 401 || response.status === 403) {
      updateToken();
      return;
    } else {
      data = await response.json();
    }

    const paginationParams = {
      page: data.data.page,
      pageRowCount: data.data.pageRowCount,
      totalPagesCount: data.data.totalPagesCount,
      totalRowCount: data.data.totalRowCount,
    };

    setPaginationParams(paginationParams);

    const projects = data.data.list;
    setProjects(projects);
    setIsProjectsLoaded(true);
  };

  useEffect(() => {
    fetchProjects();
  }, [params, isProjectMoved, paginationQuery]);

  useEffect(() => {
    if (submitButtonPressed) {
      fetchProjects();
    }
    setSubmitButtonPressed(false);
  }, [submitButtonPressed]);

  return (
    <>
      <h3>{getStageTitle()}</h3>
      {!isProjectsLoaded ? (
        <Spinner />
      ) : !projects || projects.length === 0 ? (
        <p className="workspace__no-project-message">
          Нет {departmentId === "tender" ? "тендеров" : "проектов"}...
        </p>
      ) : (
        <ProjectsTableTender
          projects={projects}
          departmentId={params.departmentId}
        />
      )}
    </>
  );
};
export default StageTender;
