import { useState, createContext, useContext, useEffect } from "react";
import { fetchContracts } from "entities/contracts/service/fetchContracts";
import { fetchEmployees } from "entities/contracts/service/fetchEmployees";
import { Contract } from "entities/contracts/models/Contract/Contract";
import { dateToString } from "shared/formatDate";
import AuthContext from "./AuthContext";
import fetchWorkers from "utils/fetchWorkers";
import { generateToken } from "shared/generateToken";
import { api } from "settings/Api";
import { fetchAccountInfo } from "utils/fetchAccountInfo";
import { fetchAccount } from "utils/fetchAccount";
import { userIsAgent } from "utils/userIsAgent";

const ContractContext = createContext();

export const ContractProvider = ({ children }) => {
  const [isContractsLoaded, setIsContractsLoaded] = useState(false);

  const [commercialEmployees, setCommercialEmployees] = useState([]);
  const [tenderEmployees, setTenderEmployees] = useState([]);
  const [labEmployees, setLabEmployees] = useState([]);

  const [workers, setWorkers] = useState([]);

  const { updateToken } = useContext(AuthContext);

  const [sortIsShowed, setSortIsShowed] = useState(false);
  const [searchIsShowed, setSearchIsShowed] = useState(false);
  const [selectedSortField, setSelectedSortField] = useState();
  const [selectedAdditionalField, setSelectedAdditionalField] = useState();

  const sortFields = {
    id: "ID договора",
    customerINN: "ИНН заказчика",
    contractNumber: "Номер договора",
    innerNumber: "Внутренний номер",
    conclusionContractDate: "Дата заключения договора",
    plannedExecutionDate: "Плановая дата исполнения",
    actDate: "Дата акта",
    price: "Цена договора",
    personInCharge: "Ответственный",
  };

  const additionalFields = {
    contractConclusionDepartmentId: "Отдел заключения договора",
    assesmentTypes: "Тип оценки",
    innerNumber: "Внутренний номер",
    conclusionContractDate: "Дата заключения договора",
    plannedExecutionDate: "Плановая дата исполнения",
    actDate: "Дата акта",
    price: "Цена договора",
    personInCharge: "Ответственный",
    executionDescription: "Комментарий к исполнению",
    contactData: "Контактные данные",
  };

  const initialSortParams = {
    SortMethod: null,
    SortField: null,
    AdditionalInfo: null,
  };

  const [sortParams, setSortParams] = useState(initialSortParams);

  const [account, setAccount] = useState(null);
  const [page, setPage] = useState(1);
  const [prevPage, setPrevPage] = useState(page);
  const [totalCountPages, setTotalCountPages] = useState(1);
  const [totalRowCount, setTotalRowCount] = useState(0);

  const initialSearchParams = {
    id: null,
    ContractNumber: null,
    CustomerINN: null,
    FromConclusionContractDate: null,
    ToConclusionContractDate: null,
    FromPlannedExecutionDate: null,
    ToPlannedExecutionDate: null,
    CommercialDepartmentUserId: null,
    FromActDate: null,
    ToActDate: null,
    TenderDepartmentUserId: null,
    FromPaymentDate: null,
    ToPaymentDate: null,
    LaboratoryDepartmentUserId: null,
    ContractConclusionDepartmentId: null,
    WorkFlowStatuses: null,
    SearchString: null,
  };

  const [contracts, setContracts] = useState([]);
  const [values, setValues] = useState(initialSearchParams);

  const getContracts = (stage) => {
    const accountOrgId = account?.organizationId;
    if (stage.query === null) return;
    if (prevPage !== page) {
      setPrevPage(page);
    } else {
      setSelectedSortField(sortParams.SortField ? sortParams.SortField : null);
    }
    Object.keys(values).forEach((key) => {
      if (key.includes("Date")) {
        values[key] = dateToString(values[key]);
      }
    });
    const params = { ...sortParams, ...values };
    fetchContracts(
      params,
      page,
      stage.query,
      accountOrgId === 1 ? 20 : 2500
    ).then((res) => {
      if (res === 401 || res === 403) {
        updateToken();
        return;
      }
      const contractsList = [];
      for (let listItem of res.list) {
        const newItem = new Contract({
          ...listItem,
          customer: { ...listItem.customer },
          workflow: { ...listItem.workFlow },
        });
        contractsList.push(newItem);
      }

      if (account?.organizationId) {
        if (accountOrgId === 1) {
          setContracts(contractsList);
          setTotalCountPages(res.totalPagesCount);
          setTotalRowCount(res.totalRowCount);
          setIsContractsLoaded(false);
          return;
        } else {
          userIsAgent().then((result) => {
            if (result) {
              console.log(contractsList);
              const items = contractsList.filter((record) => {
                return record.personInCharge.organizationId === accountOrgId;
              });

              setContracts(items);
              setTotalCountPages(items?.length / 20);
              setTotalRowCount(items?.length);
            } else {
              setContracts(contractsList);
            }
          });
        }
      }
      // setTotalCountPages(res.totalPagesCount);
      // setTotalRowCount(res.totalRowCount);
      setIsContractsLoaded(false);
    });
  };

  const getEmployees = () => {
    fetchEmployees("CommercialDepartment").then((res) => {
      if (res === 401 || res === 403) {
        updateToken();
        return;
      }
      setCommercialEmployees(res);
    });
    fetchEmployees("TenderDepartment").then((res) => {
      if (res === 401 || res === 403) {
        updateToken();
        return;
      }
      setTenderEmployees(res);
    });
    fetchEmployees("Laboratory").then((res) => {
      if (res === 401 || res === 403) {
        updateToken();
        return;
      }
      setLabEmployees(res);
    });
  };

  const getWorkers = () => {
    fetchWorkers(setWorkers);
  };

  const findEmployee = (id, depName) => {
    if (depName === "Laboratory") {
      return labEmployees.find((emp) => emp.id === id);
    }
    if (depName === "TenderDepartment") {
      return tenderEmployees.find((emp) => emp.id === id);
    }
    if (depName === "CommercialDepartment") {
      return commercialEmployees.find((emp) => emp.id === id);
    }
  };

  const findWorker = (id) => {
    const index = workers.findIndex((emp) => emp.id === id);
    const user = workers[index];
    return user;
  };

  const [tempContract, setTempContract] = useState();

  const initTempContract = async (departmentId, userInfo) => {
    try {
      let user = userInfo ? userInfo : await fetchAccountInfo();
      setTempContract({
        customer: {
          inn: null,
          name: null,
        },
        assesmentTypes: [],
        contractNumber: null,
        conclusionContractDate: null,
        plannedExecutionDate: null,
        contractConclusionDepartmentId: departmentId,
        additionalName: null,
        departments: [],
        personInChargeId: user.id || null,
        branchOfficeId: user?.branchOfficeId || null,
        organisationId: user?.organizationId || null,
        regions: [],
        workspaceCount: {
          pk: null,
          sout: null,
          opr: null,
        },
      });
    } catch (e) {
      console.log(e.message);
    }
  };

  const updateTempContract = (key, value) => {
    setTempContract((prev) => {
      return {
        ...prev,
        [key]: value,
      };
    });
  };

  const toggleAllContractCheck = async (stage) => {
    const token = generateToken();
    try {
      const stageName =
        stage?.title === "Подготовка и исполнение" ? "acts" : "finish";
      await api(`/api/sout/workflow/contracts/continue/${stageName}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      getContracts(stage);
    } catch (e) {
      alert(`Ошибка: ${e.message}`);
    }
  };

  return (
    <ContractContext.Provider
      value={{
        isLoading: isContractsLoaded,
        setIsLoading: setIsContractsLoaded,
        commercialEmployees,
        tenderEmployees,
        labEmployees,
        setCommercialEmployees,
        setTenderEmployees,
        setLabEmployees,
        workers,
        account,
        findWorker,
        setWorkers,
        sortIsShowed,
        setSortIsShowed,
        searchIsShowed,
        setSearchIsShowed,
        setAccount,
        selectedSortField,
        setSelectedSortField,
        selectedAdditionalField,
        setSelectedAdditionalField,
        sortFields,
        additionalFields,
        initialSortParams,
        initialSearchParams,
        sortParams,
        setSortParams,
        page,
        setPage,
        prevPage,
        setPrevPage,
        totalCountPages,
        setTotalCountPages,
        setTotalRowCount,
        totalRowCount,
        contracts,
        setContracts,
        values,
        setValues,
        getContracts,
        getEmployees,
        getWorkers,
        toggleAllContractCheck,
        findEmployee,
        tempContract,
        setTempContract,
        updateTempContract,
        initTempContract,
      }}
    >
      {children}
    </ContractContext.Provider>
  );
};

export default ContractContext;
