import React, { useState, useEffect, useRef, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Sidebar from "../../../../components/Sidebar";
import postTenderData from "../../../../utils/PostTenderData";
import Asterisk from "../../../../components/Asterisk";
import SelectRegions from "../../../../components/common/SelectRegions";
import { Select } from "../../../../components/Select/Select";
import { LabelTitle } from "shared/ui/LabelTitle/LabelTitle";
import { DatePicker } from "components/DateInput/DateInput";
import { dateToString, dateToStringWithTime } from "shared/formatDate";
import { Text } from "shared/ui/Text/Text";
import DateWithTimeInput from "components/DateInput/DateWithTimeInput";
import AuthContext from "context/AuthContext";
import { isTenderUser } from "utils/defineUser";
import { api, showValidateMessage } from "settings/Api";
import { generateToken } from "shared/generateToken";
import SelectWithSearch from "components/SelectWithSearch";
import { TextInput } from "components/TextInput/TextInput";

const NewTenderPage = () => {
  const { state } = useLocation();
  const { projectId, actionType } = state;

  const isEditPage = actionType === "edit" ? true : false; //'add' or 'edit'
  const navigate = useNavigate();
  const { role } = useContext(AuthContext);

  useEffect(() => {
    if (isEditPage) {
      fetchTender();
    }
  }, []);

  const [values, setValues] = useState({});
  const [tender, setTender] = useState({});

  const [regError, setRegError] = useState(true);

  const [isNotariusChecked, setIsNotariusChecked] = useState(false);

  const fetchTender = async () => {
    const token = generateToken();
    let response = await api(`/api/sout/admin/tenders?id=${projectId}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    let data = await response.json();
    console.log(data?.data?.list[0]);
    setTender(data?.data?.list[0]);
  };

  useEffect(() => {
    if (isEditPage) {
      setValues({
        ...values,
        customerAdditionalName: tender?.customerAdditionalName,
        eis: tender?.eis,
        price: tender?.price,
        contracts: tender?.contracts,
        publicationDate: tender?.publicationDate, //Дата публикации
        acceptingOffersDeadline: tender?.acceptingOffersDeadline
          ? new Date(tender?.acceptingOffersDeadline)
          : null, // Дата окончания подачи заявок
        startMaxPrice: tender?.startMaxPrice, // НМЦК
        ourPrice: tender?.ourPrice, // Наша цена
        offerSecurity: tender?.offerSecurity ? "true" : "false",
        failureDescription: tender?.failureDescription,
        contractsCount: tender?.contractsCount,
        tradingFloor: tender?.tradingFloor?.id,
        operationTypeId: tender?.operationTypeId,
        auctionDate: tender?.auctionDate ? new Date(tender?.auctionDate) : null,
        summaryReportDate: tender?.summaryReportDate,
        workplacesCount: tender?.workplacesCount,
        aggregatorLink: tender?.aggregatorLink,
        description: tender?.description,
        hasTradingRobot: tender?.hasTradingRobot ? "true" : "false",
        regions: tender?.regions,
        legalBasesId: tender?.legalBasesId,
        offerSecurityAmount: tender?.offerSecurityAmount,
        assessmentTypeList: tender?.assessmentTypeList,
        winner: tender?.winner?.id, //Победитель
        winnerPrice: tender?.winnerPrice, //Цена победителя
        personInCharge: tender?.personInCharge?.id, //Ответственный сотрудник
        managerINN: tender?.manager?.inn, //ИНН организатора
        managerName: tender?.manager?.name, //Наименование организатора
        managerContactData: tender?.manager?.contactData,
      });
      setCustomerNameViaINN(tender?.manager?.name);
    }
  }, [tender]);

  const tradingFloorCommissionAmountRef = useRef(null);
  const contractSecurityAmountRef = useRef(null);
  const offerSecurityAmountRef = useRef(null);

  const resetTradingFloorCommissionAmount = () =>
    setValues({ ...values, tradingFloorCommissionAmount: 0 });
  const resetContractSecurityAmount = () =>
    setValues({ ...values, securityAmount: 0 });
  const resetOfferSecurityAmount = () =>
    setValues({ ...values, offerSecurityAmount: 0 });

  const inputChangeHandler = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };

  const updateRegions = (updatedRegions) => {
    setValues({ ...values, regions: updatedRegions });
    setRegError(false);
  };

  const [tradingFloors, getTradingFloors] = useState([]);
  const [competitors, getCompetitors] = useState([]);
  const [tenderUsers, getTenderUsers] = useState("");
  const [commercialUsers, getCommercialUsers] = useState("");
  const [laboratoryUsers, getLaboratoryUsers] = useState("");
  const [workers, getWorkers] = useState("");
  const [regions, setRegions] = useState("");
  const [request_body, set_request_body] = useState();

  useEffect(() => {
    fetchUsers("TenderDepartment");
    fetchUsers("CommercialDepartment");
    fetchUsers("Laboratory");
    fetchWorkers();
    fetchCompetitors();
    fetchTradingFloors();
    fetchRegions();
  }, []);

  const fetchUsers = async (departmentName) => {
    const token = JSON.parse(localStorage.getItem("authTokens")).data
      .accessToken;
    let response = await api(
      `/api/sout/helpers/handbooks/engineers?department=${departmentName}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    let data = await response.json();

    switch (departmentName) {
      case "TenderDepartment":
        getTenderUsers(data.data);
        break;
      case "CommercialDepartment":
        getCommercialUsers(data.data);
        break;
      case "Laboratory":
        getLaboratoryUsers(data.data);
        break;
      default:
        throw new Error("Fetch error: department cannot found!");
    }
  };

  const fetchWorkers = async () => {
    const token = JSON.parse(localStorage.getItem("authTokens")).data
      .accessToken;
    let response = await api(`/api/sout/helpers/handbooks/workers`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        // 'Authorization':  'String(authTokens.access) ,
      },
    });
    let data = await response.json();
    getWorkers(data.data);
  };

  const fetchCompetitors = async () => {
    const token = JSON.parse(localStorage.getItem("authTokens")).data
      .accessToken;

    let response = await api(`/api/sout/helpers/handbooks/competitors`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        // 'Authorization':  'String(authTokens.access) ,
      },
    });
    let data = await response.json();
    getCompetitors(data.data);
  };

  const fetchTradingFloors = async () => {
    const token = JSON.parse(localStorage.getItem("authTokens")).data
      .accessToken;

    let response = await api(`/api/sout/helpers/handbooks/tradingfloors`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        // 'Authorization':  'String(authTokens.access) ,
      },
    });
    let data = await response.json();
    getTradingFloors(data.data);
  };

  const addUser = () => {
    if (isTenderUser()) {
      if (!isEditPage) {
        return {
          ...Array.from(tenderUsers)?.find(
            (item) => item.id === Number(localStorage.getItem("__user-id"))
          ),
          departmentId: "TenderDepartment",
        };
      }
      return tender?.personInCharge;
    } else {
      if (values?.personInCharge) {
        return {
          ...Array.from(tenderUsers)?.find(
            (item) => item.id === Number(values?.personInCharge)
          ),
          departmentId: "TenderDepartment",
        };
      }
      return null;
    }
  };

  const [focused, setFocused] = useState({
    managerName: false,
    inn: false,
    assesmentTypeIdY: false,
    assesmentTypeIdX: false,
    tenderId: false,
    tradingFloor: false,
    ourPrice: false,
    description: false,
    failureDescription: false,
    winnerPrice: false,
    winner: false,
    personInCharge: false,
    publicationDate: false,
    assessmentTypeId: false,
    acceptingOffersDeadline: false,
    startMaxPrice: false,
    offerAmount: false,
    auctionDate: false,
    peopleForMedicalExamination: false,
    managerINN: false,
    eis: false,
    contractsCount: false,
  });

  const fetchRegions = async () => {
    const token = JSON.parse(localStorage.getItem("authTokens")).data
      .accessToken;
    let response = await api("/api/sout/helpers/handbooks/regions", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    let data = await response.json();
    setRegions(data.data);
  };

  const [displayErrors, setDisplayErrors] = useState(false);

  const convertDate = (date) => {
    return date ? dateToString(date) : null;
  };

  const convertDateTime = (date) => {
    return date ? dateToStringWithTime(date) : null;
  };

  useEffect(() => {
    if (values?.regions)
      setRegError(values?.regions?.length === 0 ? true : false);
    set_request_body({
      eis: values.eis, //ЕИС (номер на площадке)
      publicationDate: convertDate(values?.publicationDate), //Дата публикации
      acceptingOffersDeadline: convertDateTime(values?.acceptingOffersDeadline), // Дата окончания подачи заявок
      startMaxPrice: values.startMaxPrice, // НМЦК
      manager: {
        inn: values.managerINN, //ИНН организатора
        name: values.managerName, //Наименование организатора
        contactData: values.managerContactData,
      },
      personInCharge: addUser(),
      auctionDate: convertDateTime(values.auctionDate),
      summaryReportDate: convertDate(values.summaryReportDate),
      offerSecurity: values.offerSecurity === "true" ? true : false,
      offerSecurityAmount: values.offerSecurityAmount || null,
      contractsCount: values?.contractsCount || null,
      description: values.description,
      failureDescription: values.failureDescription,
      winnerPrice: values.winnerPrice,
      ourPrice: values.ourPrice || null, //Наша цена
      workplacesCount: values.workplacesCount || null,
      aggregatorLink: values.aggregatorLink || null,
      hasTradingRobot: values.hasTradingRobot === "true" ? true : false,
      tradingFloor: tradingFloors?.find(
        (item) => item.id == values?.tradingFloor
      ),
      customerAdditionalName: values?.customerAdditionalName,
      contracts: values?.contracts || [],
      contractPrice: tender?.contractPrice,
      winner: Array.from(competitors)?.find(
        (item) => item.id === Number(values.winner)
      ),
      regions: values?.regions,
      price: values?.price,
      operationTypeId: values.operationTypeId || null,
      legalBasesId: values.legalBasesId || null,
      assessmentTypeList: values.assessmentTypeList || null,
    });
  }, [values]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    let res;
    if (!regError) {
      isEditPage
        ? (res = await postTenderData(request_body, actionType, projectId))
        : (res = await postTenderData(request_body, actionType));

      if (res?.status !== 200) {
        if (res?.status === 400) showValidateMessage(res?.data?.errors);
        if (isEditPage) {
          alert(`Ошибка: ${res?.status}. Тендер не был изменён.`);
        } else {
          alert(`Ошибка: ${res?.status}. Тендер не был добавлен`);
        }
        return false;
      }
      if (isEditPage) {
        alert("Тендер успешно изменён");
        navigate(-1);
      } else {
        alert("Тендер успешно добавлен");
        navigate("/sout/tender/draft");
      }
    } else {
      alert("Укажите регион");
    }
  };

  const [customerNameViaINN, setCustomerNameViaINN] = useState();

  const customerNameHandler = (name, inn) => {
    setCustomerNameViaINN(name);
    setValues({
      ...values,
      managerName: name,
      managerINN: inn,
    });
  };

  const [isSearching, setIsSearching] = useState(false);

  const getCustomerNameByINN = async (e) => {
    const inn = e.target.value;

    inputChangeHandler({ target: { name: e.target.name, value: inn } });

    if (inn.length === 10 || inn.length === 12) {
      setIsSearching(true);
      const token = JSON.parse(localStorage.getItem("authTokens")).data
        .accessToken;

      api(`/api/account/fetchinfobyinn?inn=${inn}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.success) {
            setTimeout(() => {
              customerNameHandler(data.organizationName, inn);
              setIsSearching(false);
            });
          } else {
            setCustomerNameViaINN(false);
            setIsSearching(false);
            return;
          }
        });
    } else {
      setCustomerNameViaINN(false);
      setIsSearching(false);
    }
  };

  return (
    <>
      <main className="workspace">
        <Sidebar />
        <div className="workspace__content">
          <section className="workspace__wrapper">
            <h3 className="title workspace__page-title">
              <span
                style={{
                  fontSize: "22px",
                  lineHeight: "1px",
                  marginRight: "10px",
                }}
              >
                &#10010;
              </span>
              {isEditPage ? "Редактировать" : "Добавить"}
              &nbsp;тендер
            </h3>
            <form
              onSubmit={handleSubmit}
              className={`form ${displayErrors ? "form--isValid" : ""}`}
              style={{
                width: "1000px",
                justifyContent: "space-between",
                flexWrap: "wrap",
              }}
            >
              <section className="form__column" style={{ width: "300px" }}>
                <label className="workspace__input-title">
                  ИНН организатора:
                  <Asterisk />
                  <input
                    className="input"
                    type="text"
                    maxLength="12"
                    name="managerINN"
                    value={values.managerINN}
                    onChange={(e) => {
                      getCustomerNameByINN(e);
                    }}
                    onPaste={(e) => {
                      getCustomerNameByINN(e);
                    }}
                    onBlur={() => setFocused({ ...focused, managerINN: true })}
                    focused={focused.managerINN.toString()}
                    required={true}
                    pattern="^(\d{10}|\d{12})$"
                  />
                  <span className="error-message">Введите 10 или 12 цифр</span>
                </label>
                <div
                  className="workspace__input-title"
                  style={{ maxWidth: "380px" }}
                >
                  <span>Наименование заказчика</span>
                  {isEditPage ? (
                    <p>{customerNameViaINN}</p>
                  ) : customerNameViaINN === undefined ? (
                    <p>Введите ИНН заказчика...</p>
                  ) : isSearching ? (
                    <p>Поиск по базе данных...</p>
                  ) : customerNameViaINN ? (
                    <p>{customerNameViaINN}</p>
                  ) : (
                    <Text theme="red">Заказчик не найден!</Text>
                  )}
                  <span className="error-message">
                    Это поле не должно быть пустым
                  </span>
                </div>
                <label className="workspace__input-title">
                  Ссылка от агрегатора:
                  <Asterisk />
                </label>
                <input
                  className="input"
                  type="text"
                  required={true}
                  name="aggregatorLink"
                  value={values.aggregatorLink}
                  onChange={(e) => inputChangeHandler(e)}
                />
                <TextInput
                  label="Доп. наименование"
                  type="text"
                  name="customerAdditionalName"
                  alignLabel="left"
                  labelHeight="24px"
                  value={values.customerAdditionalName}
                  onChange={(e) => inputChangeHandler(e)}
                />
                <label className="workspace__input-title">
                  Контактные данные:
                </label>
                <textarea
                  className="textarea"
                  name="managerContactData"
                  value={values.managerContactData}
                  onChange={(e) => inputChangeHandler(e)}
                />
                <SelectRegions
                  label={"Регион:"}
                  regions={values.regions}
                  required={true}
                  updateRegions={updateRegions}
                />
                {regError && (
                  <span className="error-message">Выберите регион</span>
                )}
              </section>
              <section
                className="form__column"
                style={{
                  minWidth: "300px",
                  maxWidth: "300px",
                  marginLeft: "0",
                }}
              >
                <label className="workspace__input-title">
                  ЕИС (ID на площадке):
                  <input
                    className="input"
                    type="text"
                    name="eis"
                    value={values.eis}
                    onChange={(e) => inputChangeHandler(e)}
                    onBlur={() => setFocused({ ...focused, eis: true })}
                    focused={focused.eis.toString()}
                  />
                  <span className="error-message">Укажите ЕИС ID</span>
                </label>

                <label className="workspace__input-title">
                  Площадка:
                  <select
                    className="input search-area__select"
                    name="tradingFloor"
                    value={values.tradingFloor}
                    onChange={(e) => inputChangeHandler(e)}
                    onBlur={() =>
                      setFocused({ ...focused, tradingFloor: true })
                    }
                    focused={focused.tradingFloor.toString()}
                  >
                    <option value={""}>-</option>
                    {tradingFloors &&
                      tradingFloors.map(({ id, name }) => (
                        <option key={id} value={id}>
                          {" "}
                          {name}{" "}
                        </option>
                      ))}
                  </select>
                  <span className="error-message">Выберите площадку</span>
                </label>

                <label className="workspace__input-title">
                  Вид процедуры
                  <select
                    className="input search-area__select"
                    name="operationTypeId"
                    value={values.operationTypeId}
                    onChange={(e) => inputChangeHandler(e)}
                    onBlur={() =>
                      setFocused({ ...focused, tradingFloorId: true })
                    }
                    focused={focused?.operationTypeId}
                  >
                    <option value="">-</option>
                    <option value="None">Нет</option>
                    <option value="DigitalAuction">Электронный аукцион</option>
                    <option value="RequestForQuotations">
                      Запрос котировок или предложений
                    </option>
                    <option value="Other">Другое</option>
                  </select>
                  <span className="error-message">Выберите вид процедуры</span>
                </label>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <label
                    className="workspace__input-title"
                    style={{ width: "48%" }}
                  >
                    <Select
                      label={"Закон закупки"}
                      name={"legalBasesId"}
                      value={values.legalBasesId}
                      onChange={(e) => inputChangeHandler(e)}
                      dataSrc="local"
                      data={{
                        values: {
                          None: "Нет",
                          Gov: "44 ФЗ",
                          PartialGov: "223 ФЗ",
                          Other: "Другое",
                        },
                      }}
                    />
                  </label>
                  <label
                    className="workspace__input-title"
                    style={{ width: "48%" }}
                  >
                    <Select
                      label="Кто отыгрывает?"
                      name={"hasTradingRobot"}
                      value={values.hasTradingRobot}
                      onChange={(e) => inputChangeHandler(e)}
                      dataSrc="local"
                      data={{
                        values: {
                          true: "Робот",
                          false: "Не робот",
                        },
                      }}
                    />
                  </label>
                </div>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <label
                    className="workspace__input-title"
                    style={{ width: "48%" }}
                  >
                    <LabelTitle>НМЦК:</LabelTitle>
                    <input
                      className="input"
                      type="number"
                      name="startMaxPrice"
                      value={values.startMaxPrice}
                      onChange={(e) => inputChangeHandler(e)}
                      onBlur={() =>
                        setFocused({ ...focused, startMaxPrice: true })
                      }
                      onWheel={(e) => e.target.blur()}
                      focused={focused.startMaxPrice.toString()}
                    />
                    <span className="error-message">Введите НМЦК</span>
                  </label>
                  <label
                    className="workspace__input-title"
                    style={{ width: "48%" }}
                  >
                    <LabelTitle>Наша цена:</LabelTitle>
                    <input
                      className="input"
                      type="number"
                      name="ourPrice"
                      value={values.ourPrice}
                      onChange={(e) => inputChangeHandler(e)}
                      onBlur={() => setFocused({ ...focused, ourPrice: true })}
                      focused={focused.ourPrice}
                      onWheel={(e) => e.target.blur()}
                    />
                    <span className="error-message">Введите нашу цену</span>
                  </label>
                </div>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <label
                    className="workspace__input-title"
                    style={{ width: "48%" }}
                  >
                    <LabelTitle>Цена:</LabelTitle>
                    <input
                      className="input"
                      type="number"
                      name="price"
                      value={values.price}
                      onChange={(e) => inputChangeHandler(e)}
                      onWheel={(e) => e.target.blur()}
                    />
                  </label>
                </div>
                <label className="workspace__input-title">Победитель:</label>

                <SelectWithSearch
                  values={competitors}
                  onSelect={(e) =>
                    setValues({
                      ...values,
                      winner: competitors?.find(
                        (item) => item?.id == e.target.id
                      )?.id,
                    })
                  }
                  onDelete={() => setValues({ ...values, winner: null })}
                  selectValue={values?.winner}
                />
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <label
                    className="workspace__input-title"
                    style={{ width: "48%" }}
                  >
                    Цена победителя:
                    <input
                      step="any"
                      className="input"
                      type="number"
                      name="winnerPrice"
                      value={values.winnerPrice}
                      onChange={(e) => inputChangeHandler(e)}
                      onWheel={(e) => e.target.blur()}
                    />
                  </label>
                  {/* <label className="workspace__input-title" style={{ width: '48%' }}>
                    Число рабочих мест:

                    <input
                      className="input"
                      type="number"
                      name="workplacesCount"
                      value={values.workplacesCount}
                      onChange={(e) => inputChangeHandler(e)}
                      onWheel={(e) => e.target.blur()}
                    />
                  </label> */}
                  <label
                    className="workspace__input-title"
                    style={{ width: "48%" }}
                  >
                    Кол-во договоров:
                    <input
                      className="input"
                      type="number"
                      name="contractsCount"
                      value={values.contractsCount}
                      onChange={(e) => inputChangeHandler(e)}
                      onWheel={(e) => e.target.blur()}
                      onBlur={() =>
                        setFocused({
                          ...focused,
                          contractsCount: true,
                        })
                      }
                      // focused={focused.contractsCount}
                    />
                  </label>
                </div>
              </section>
              <section
                className="form__column workspace__ensure-wrapper"
                style={{
                  minWidth: "300px",
                  maxWidth: "300px",
                  marginLeft: "0",
                }}
              >
                {!isTenderUser() && (
                  <>
                    <label className="workspace__input-title">
                      Ответственный:
                    </label>
                    <select
                      className="input search-area__select"
                      name="personInCharge"
                      value={values.personInCharge}
                      onChange={(e) => inputChangeHandler(e)}
                    >
                      <option value={"null"}>-</option>
                      {tenderUsers &&
                        tenderUsers.map(({ id, name }) => (
                          <option key={id} value={id}>
                            {" "}
                            {name}{" "}
                          </option>
                        ))}
                    </select>
                  </>
                )}

                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <label style={{ width: "48%" }}>
                    <LabelTitle height={40}>Дата публикации:</LabelTitle>
                    <DatePicker
                      className="input"
                      name="publicationDate"
                      value={values?.publicationDate || null}
                      onChange={(date) =>
                        setValues({ ...values, publicationDate: date })
                      }
                      min={new Date("01.01.2000")}
                    />
                    <span className="error-message">
                      Укажите корректную дату
                    </span>
                  </label>
                  <label style={{ width: "48%" }}>
                    <LabelTitle height={40}>
                      Окончание подачи заявки:
                    </LabelTitle>
                    <DateWithTimeInput
                      className="input"
                      name="acceptingOffersDeadline"
                      date={values?.acceptingOffersDeadline}
                      onChange={(date) =>
                        setValues({ ...values, acceptingOffersDeadline: date })
                      }
                      min={new Date("01.01.2000")}
                    />
                    <span className="error-message">
                      Укажите корректную дату
                    </span>
                  </label>
                </div>

                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <label style={{ width: "48%" }}>
                    <LabelTitle height={40}>Дата аукциона:</LabelTitle>
                    <DateWithTimeInput
                      disabled={values?.operationTypeId !== "DigitalAuction"}
                      className="input"
                      name="auctionDate"
                      date={values?.auctionDate}
                      onChange={(date) =>
                        setValues({ ...values, auctionDate: date })
                      }
                      min={new Date("01.01.2000")}
                    />
                    <span className="error-message">
                      Укажите корректную дату
                    </span>
                  </label>
                  <label style={{ width: "48%" }}>
                    <LabelTitle height={40}>
                      Дата итогового протокола:
                    </LabelTitle>
                    <DatePicker
                      className="input"
                      name="summaryReportDate"
                      value={values?.summaryReportDate || null}
                      onChange={(date) =>
                        setValues({ ...values, summaryReportDate: date })
                      }
                      min={new Date("01.01.2000")}
                    />
                  </label>
                </div>

                <label className="workspace__input-title">
                  Комментарий к заявке:
                </label>
                <textarea
                  className="textarea"
                  cols="50"
                  rows="5"
                  name="description"
                  value={values.description}
                  onChange={(e) => inputChangeHandler(e)}
                />

                <label className="workspace__input-title">
                  Комментарий о причине неподачи:
                </label>
                <textarea
                  className="textarea"
                  cols="50"
                  rows="5"
                  name="failureDescription"
                  onBlur={() =>
                    setFocused({ ...focused, failureDescription: true })
                  }
                  focused={focused.failureDescription}
                  value={values.failureDescription}
                  onChange={(e) => inputChangeHandler(e)}
                />
              </section>
              <div
                className="line"
                style={{
                  width: "1000px",
                  borderBottom: "2px solid #000",
                  margin: "15px 0 10px 0",
                  flexBasis: "100%",
                }}
              ></div>
              <div
                className="buttons"
                style={{
                  display: "flex",
                  maxWidth: "300px",
                  justifyContent: "space-between",
                  margin: "auto",
                  flexBasis: "100%",
                }}
              >
                <button
                  type="submit"
                  className="button button--purple"
                  onClick={() => setDisplayErrors(true)}
                >
                  {isEditPage ? "Сохранить" : "Добавить"}
                </button>
                <button
                  className="button button--purple"
                  onClick={(e) => {
                    e.preventDefault();
                    navigate(-1);
                  }}
                >
                  Отмена
                </button>
              </div>
            </form>
          </section>
        </div>
      </main>
    </>
  );
};
export default NewTenderPage;
