import { classNames } from "shared/classNames";
import Asterisk from "../../components/Asterisk";
import { LabelTitle } from "shared/ui/LabelTitle/LabelTitle";
const Select = ({
  className,
  classNameLabel,
  onChange,
  value,
  dataSrc,
  data,
  label,
  defaultValue = null,
  labelHeight = 20,
  name,
  defaultOption,
  alignLabel,
  disabled = false,
  required,
}) => {
    console.log("data", data);
    return (
    <label className={classNameLabel}>
      <LabelTitle align={alignLabel} height={labelHeight}>
        {data?.label ? data.label : label}
        {required && <Asterisk />}
      </LabelTitle>
      <select
        className={`select ${className}`}
        onChange={onChange}
        name={data?.name ? data.name : name || "Lorem ipsum dolor set amet."}
        value={value}
        defaultValue={defaultValue}
        disabled={disabled}
        required={required}
      >
        {defaultOption ? (
          <option value={defaultOption.value}> {defaultOption.text} </option>
        ) : (
          <option value="null"> - </option>
        )}
        {data &&
          dataSrc === "local" &&
          Object.keys(data.values).map((key) => (
            <Option key={key} value={key} text={data.values[key]} />
          ))}
        {data &&
          dataSrc === "server" &&
          data.map(({ id, name, lastName }) => (
            <Option key={id} value={id} text={lastName ? `${name} ${lastName}` : name} />
          ))}
      </select>
    </label>
  );
};

const Option = ({ value, text }) => <option value={value}>{text}</option>;

Select.defaultProps = {
  className: "",
  title: null,
  name: null,
};

export { Select };
