import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Sidebar from "../../../components/Sidebar";
import AdminActionButtons from "../../../components/AdminActionButtons";
import Spinner from "../../../components/Spinner";
import { translateUser } from "../../../utils/stages";
import { api } from "settings/Api";
import { Select } from "components/Select/Select";
import { fetchOrganizations } from "utils/fetchOrganizations";
import { MdEdit } from "react-icons/md";

const ListOfUsers = () => {
  const [employees, setEmployees] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [filtredEmployees, setFiltredEmployees] = useState([]);
  const [status, setStatus] = useState("all"); //all, unblocked, blocked
  const [org, setOrg] = useState([]);
  const [filial, setFilial] = useState([]);
  const [currentOrg, setCurrentOrg] = useState("null");
  const [currentFilial, setCurrentFilial] = useState("null");
  const [currentDep, setCurrentDep] = useState("null");

  useEffect(() => {
    const fetchEmployees = async (setIsLoading, setEmployees) => {
      const token = JSON.parse(localStorage.getItem("authTokens")).data
        .accessToken;

      api("/api/sout/admin/users", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          // 'Authorization':  'String(authTokens.access) ,
        },
      })
        .then((res) => res.json())
        .then((res) => {
          const data = res;

          setIsLoading(false);
          setEmployees(data.data);
          setFiltredEmployees(data.data);
        });
    };
    fetchEmployees(setIsLoading, setEmployees);
    fetchOrganizations().then((res) => setOrg(res));
  }, []);

  useEffect(() => {
    setFilial(org?.find((i) => i.id === parseInt(currentOrg))?.branchOffices);
  }, [currentOrg]);

  const displayAll = () => {
    setStatus("all");
    setCurrentDep("null");
    setCurrentOrg("null");
    setCurrentFilial("null");
    setFiltredEmployees(employees);
  };

  const displayBlocked = () => {
    const blocked = employees.filter((emp) => emp.isActive === false);
    setFiltredEmployees(blocked);
    setStatus("blocked");
  };

  const displayUnblocked = () => {
    const unblocked = employees.filter((emp) => emp.isActive === true);
    setFiltredEmployees(unblocked);
    setStatus("unblocked");
  };

  function filterData(data, selectedOrg, selectedDep, selectedFilial) {
    return data.filter((item) => {
      const orgCondition =
        selectedOrg === "null" || item.organizationId === parseInt(selectedOrg);
      const depCondition =
        selectedDep === "null" || item.departmentId === selectedDep;
      const filialCondition =
        selectedFilial === "null" ||
        item.branchOfficeId === parseInt(selectedFilial);

      return orgCondition && depCondition && filialCondition;
    });
  }
  useEffect(() => {
    if (
      currentOrg === "null" ||
      org
        ?.find((i) => i.id === parseInt(currentOrg))
        ?.branchOffices.find((bf) => bf.id === parseInt(currentFilial)) ===
        undefined
    ) {
      setCurrentFilial("null");
    }
    setFiltredEmployees(
      filterData(employees, currentOrg, currentDep, currentFilial)
    );
  }, [currentDep, currentFilial, currentOrg]);

  console.log(filtredEmployees);

  return (
    <main className="workspace">
      <Sidebar />
      <div className="workspace__content">
        <section className="workspace__wrapper">
          {isLoading ? (
            <Spinner />
          ) : (
            <>
              <h2 className="workspace__title workspace__page-title">
                Список пользователей
              </h2>
              <div
                style={{
                  marginTop: "10px",
                  marginBottom: "30px",
                  width: "700px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    height: 45,
                    width: 1050,
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <button
                    className={`button ${status === "all" && "button--active"}`}
                    onClick={displayAll}
                  >
                    Все
                  </button>
                  <button
                    className={`button ${
                      status === "unblocked" && "button--active"
                    }`}
                    onClick={displayUnblocked}
                  >
                    Активные
                  </button>
                  <button
                    className={`button ${
                      status === "blocked" && "button--active"
                    }`}
                    onClick={displayBlocked}
                  >
                    Заблокированные
                  </button>
                  <div
                    style={{
                      display: "flex",
                      width: 600,
                      marginTop: 5,
                      justifyContent: "space-between",
                      alignSelf: "end",
                    }}
                  >
                    {" "}
                    <div style={{ width: 200 }}>
                      <Select
                        classNameLabel={"workspace__input-title"}
                        onChange={(e) => {
                          setCurrentOrg(e.target.value);
                        }}
                        value={currentOrg}
                        label="Организация/физ.лицо:"
                        dataSrc="server"
                        data={org}
                      />
                    </div>
                    <div style={{ width: 200 }}>
                      <Select
                        classNameLabel={"workspace__input-title"}
                        onChange={(e) => {
                          setCurrentFilial(e.target.value);
                        }}
                        label="Филиал:"
                        dataSrc="server"
                        value={currentFilial}
                        data={filial}
                      />
                    </div>
                    <Select
                      classNameLabel={"workspace__input-title"}
                      onChange={(e) => {
                        setCurrentDep(e.target.value);
                      }}
                      label="Отдел:"
                      dataSrc="local"
                      value={currentDep}
                      data={{
                        values: {
                          CommercialDepartment: "Коммерческий отдел",
                          TenderDepartment: "Тендерный отдел",
                          Laboratory: "Лаборатория",
                        },
                      }}
                    />
                  </div>
                </div>
              </div>
              <Link to="/AddEmployee" className="button workspace__button">
                Добавить пользователя
              </Link>

              <section className="workspace__table-wrapper">
                <div className="table-wrapper">
                  <table className="table workspace__table">
                    <thead>
                      <tr>
                        <td>ID</td>
                        <td>Имя Фамилия</td>
                        <td>Логин</td>
                        <td>Отдел</td>
                        <td>Должность</td>
                        <td>Действие</td>
                      </tr>
                    </thead>
                    <tbody>
                      {filtredEmployees &&
                        filtredEmployees.map((employee) => (
                          <tr key={employee.id}>
                            <td>{employee.id}</td>
                            <td className="table__name">
                              {employee.name}&nbsp;{employee.lastName}&nbsp;
                              {employee.patronymic}
                            </td>
                            <td>{employee.userName}</td>
                            <td>{translateUser(employee.departmentId)}</td>
                            <td>{translateUser(employee.positionId)}</td>
                            <td className="table__admin-action-buttons">
                              <AdminActionButtons
                                state={employee}
                                link="/EditEmployee"
                              />
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </section>
            </>
          )}
        </section>
      </div>
    </main>
  );
};

export default ListOfUsers;
