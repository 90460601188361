import { formatDate } from 'shared/formatDate';

export class Contract {
  constructor({
    id = null,
    customer = null,
    assesmentTypes = [],
    contractNumber = null,
    innerNumber = null,
    conclusionContractDate = null,
    plannedExecutionDate = null,
    contractConclusionDepartmentId = null,
    additionalName = null,
    personInCharge = null,
    price = null,
    act = null,
    isExpired = null,
    isWarning = null,
    workspaceCount = null,
    measuringBlocksCount = null, 
    projectsCount = null,
    users = [],
    workflow = null,
    paymentAmount = null,
		paymentDate = null,
    documents= [],
    executionDescription = null,
    isDeleted = null
  }) {
    this.id = id;
    this.customer = customer;
    this.assesmentTypes = new Array(assesmentTypes);
    this.contractNumber = contractNumber;
    this.innerNumber = innerNumber;
    this.conclusionContractDate = formatDate(conclusionContractDate);
    this.plannedExecutionDate = formatDate(plannedExecutionDate);
    this.contractConclusionDepartmentId = contractConclusionDepartmentId;
    this.additionalName = additionalName;
    this.users = new Array(users);
    this.executionDescription = executionDescription;
    this.workflow = workflow;
    this.personInCharge = personInCharge;
    this.documents = documents;
    this.isDeleted = isDeleted;
    this.price = price;
    this.act = act;
    this.isExpired = isExpired;
    this.isWarning = isWarning;
    this.paymentAmount = paymentAmount;
		this.paymentDate = formatDate(paymentDate);
    this.workspaceCount = workspaceCount;
    this.measuringBlocksCount = measuringBlocksCount; 
    this.projectsCount = projectsCount;
  }
}