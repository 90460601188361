import { useEffect, useState } from "react";
import { fetchLocationByID } from "../service/fetchLocationById";
import { Grommet, Grid, Box } from "grommet";
import styled from "styled-components";
import { assessmentTypesTranslations, translateText } from "utils/stages";
import { formatDate } from "shared/formatDate";

const ViewModalRow = ({ title, value, color, type }) => {
  const ViewModalCell = styled.td`
    font-size: 14px;
    min-width: 200px;
    color: ${(props) => props.color || ""};
  `;
  const ViewModalInfoCell = styled.td`
    font-size: 14px;
    min-width: 350px;
    color: ${(props) => props.color || ""};
  `;
  return (
    <tr>
      <ViewModalCell color={color}>{title}</ViewModalCell>
      {type ? (
        <ViewModalInfoCell>{value || "-"}</ViewModalInfoCell>
      ) : (
        <ViewModalCell color={color}>{value || "-"}</ViewModalCell>
      )}
    </tr>
  );
};

const LocationsViewModal = ({ locationID, locType, modalHandler }) => {
  const [data, setData] = useState(null);
  const [dataTables, setDataTables] = useState({
    common: [],
    responsible: [],
    execution: [],
    dates: [],
  });
  const [titles, setTitles] = useState();

  useEffect(() => {
    fetchLocationByID(locationID, locType).then((res) => {
      setData(res);
    });
  }, []);

  useEffect(() => {
    if (data) {
      setDataTables({
        common: data?.tender
          ? [
              ["ID замерного блока", data.id, "black"],
              [
                "Тип замерного блока",
                data?.hasOwnProperty("measurementStatusId")
                  ? "Договорной"
                  : "Потенциальный",
                "black",
              ],
              [
                "Отдел заключения договоров",
                data?.contract
                  ? translateText(
                      data?.contract?.contractConclusionDepartmentId
                    )
                  : "Тендерный",
                "black",
              ],
              [
                "Этап замерного блока",
                data?.hasOwnProperty("measurementStatusId")
                  ? translateText(data.workFlow.workFlowStatusId)
                  : "Потенциальный",
                "black",
              ],
              [
                "Заказчик",
                data?.contract?.customer?.name || data?.tender?.manager?.name,
                "black",
              ],
              ["Доп. наименование", data.additionalName, "black"],
              [
                "ИНН заказчика",
                data?.contract?.customer?.inn || data?.tender?.manager?.inn,
                "black",
              ],
              [
                "Регионы",
                data?.regions?.map((r) => r.name).join(",\n"),
                "black",
              ],
              [
                "Контактные данные",
                data?.contract?.customer?.contactData ||
                  data?.tender?.manager?.contactData ||
                  "-",
                "black",
              ],
            ]
          : [
              ["ID замерного блока", data.id, "black"],
              [
                "Тип замерного блока",
                "Договорный",
                "black",
              ],
              ["ID договора", data.contract.id, "darkviolet"],
              [
                "Отдел заключения договоров",
                "Коммерческий",
                "black",
              ],
              [
                "Этап замерного блока",
                translateText(data.workFlow.workFlowStatusId),
                "black",
              ],
              [
                "Заказчик",
                data?.contract?.customer?.name ,
                "darkviolet",
              ],
              ["Доп. наименование", data.additionalName, "black"],
              [
                "ИНН заказчика",
                data?.contract?.customer?.inn,
                "darkviolet",
              ],
              ["Номер договора", data?.contract?.contractNumber, "darkviolet"],
              ["Регионы", data.regions.map((r) => r.name).join(",\n"), "black"],
              ["Контактные данные", data?.contactData || "-", "black"],
            ],
        responsible: [
          [
            "Замерщик",
            data.worker?.name
              ? `${data.worker.name} ${data.worker.lastName}`
              : "-",
            "black",
          ],
        ],
        dates: data?.tender
          ? [
              [
                "Дата публикации",
                formatDate(data?.tender?.publicationDate) || "-",
                "black",
              ],
              [
                "Дата окончания подачи заявки",
                formatDate(data?.tender?.acceptingOffersDeadline) || "-",
                "black",
              ],
            ]
          : [
              [
                "Дата заключения договора",
                formatDate(data?.contract?.conclusionContractDate) || "-",
                "black",
              ],
              [
                "Плановая дата исполнения",
                data?.measurementsDate
                  ? formatDate(data?.contract?.plannedExecutionDate)
                  : "-",
                "black",
              ],
              [
                "Плановые даты замеров",
                `${
                  data?.fromPlannedMeasurementsDate
                    ? formatDate(data?.fromPlannedMeasurementsDate)
                    : ""
                } - ${
                  data?.toPlannedMeasurementsDate
                    ? formatDate(data?.toPlannedMeasurementsDate)
                    : ""
                }`,
                "black",
              ],
            ],
        execution: data?.tender
          ? [
              ["ID тендера", data.tender.id, "darkviolet"],
              ["ИНН организатора", data.tender.manager.inn, "darkviolet"],
              [
                "Наименование организатора",
                data.tender.manager.name,
                "darkviolet",
              ],
            ]
          : [
              data?.hasOwnProperty("measurementStatusId") && [
                "Замеры",
                "-",
                "red",
              ],
              data?.hasOwnProperty("measurementStatusId") && [
                "Дата проведения замеров",
                data?.measurementsDate
                  ? formatDate(data?.measurementsDate)
                  : "-",
                "black",
              ],
              ["Комментарий к исполнению", data.executionDescription, "black"],
            ],
      });
    }
    setTitles({
      common: "Общие сведения",
      dates: "Даты",
      execution: data?.tender ? "Сведения о тендере" : "Исполнение",
      responsible: "Ответственный",
    });
  }, [data]);

  return (
    data && (
      <div style={{ width: 1100 }}>
        <h2
          style={{
            textAlign: "center",
            marginBottom: "20px",
          }}
        >
          {`Сведения о замерном блоке`}
        </h2>
        <Grommet style={{ display: "flex", justifyContent: "space-between" }}>
          {/* <Grid columns={['1fr', '1fr']} rows={['1fr', 'small', 'small']} areas={[
						{ name: 'common', start: [0, 0], end: [0, 2] },
						{ name: 'responsible', start: [1, 0], end: [1, 0] },
						{ name: 'dates', start: [1, 1], end: [1, 1] },
						{ name: 'execution', start: [1, 2], end: [1, 2] },
					]} gap="50px">	
						{Object.keys(dataTables).map((key) => dataTables[key].length > 0 && (key !== 'responsible' || !data?.tender) && (
							<div> 
								<h3 className="table__title" style={{ fontSize: "16px" }}>
									{titles[key]}
								</h3>
								<table
									className="table__table-view-modal"
									style={{
										borderCollapse: "collapse",
									}}
								>
									<tbody className="flex-wrapper__modal-table">
										{dataTables[key].map((elem) => (
											<ViewModalRow
												title={elem[0]}
												value={elem[1]}
												color={elem[2]}
											/>
										))}
									</tbody>
								</table>
							</div>
						))}
					</Grid> */}

          <div style={{ width: "53%" }}>
            <h3 className="table__title" style={{ fontSize: "16px" }}>
              {titles["common"]}
            </h3>
            <table
              style={{
                borderCollapse: "collapse",
              }}
            >
              <tbody className="flex-wrapper__modal-table">
                {dataTables["common"].map((elem) => (
                  <ViewModalRow
                    type
                    title={elem[0]}
                    value={elem[1]}
                    color={elem[2]}
                  />
                ))}
              </tbody>
            </table>
          </div>

          <div
            style={{ display: "flex", flexDirection: "column", width: "43%" }}
          >
            {!data?.tender && (
              <div style={{ marginBottom: 10 }}>
                <h3 className="table__title" style={{ fontSize: "16px" }}>
                  {titles["responsible"]}
                </h3>
                <table
                  className="table__table-view-modal"
                  style={{
                    borderCollapse: "collapse",
                  }}
                >
                  <tbody className="flex-wrapper__modal-table">
                    {dataTables["responsible"].map((elem) => (
                      <ViewModalRow
                        title={elem[0]}
                        value={elem[1]}
                        color={elem[2]}
                      />
                    ))}
                  </tbody>
                </table>
              </div>
            )}
            <div>
              <h3 className="table__title" style={{ fontSize: "16px" }}>
                {titles["dates"]}
              </h3>
              <table
                className="table__table-view-modal"
                style={{
                  borderCollapse: "collapse",
                }}
              >
                <tbody className="flex-wrapper__modal-table">
                  {dataTables["dates"].map((elem) => (
                    <ViewModalRow
                      title={elem[0]}
                      value={elem[1]}
                      color={elem[2]}
                    />
                  ))}
                </tbody>
              </table>
            </div>
            <div>
              <h3 className="table__title" style={{ fontSize: "16px" }}>
                {titles["execution"]}
              </h3>
              <table
                className="table__table-view-modal"
                style={{
                  borderCollapse: "collapse",
                }}
              >
                <tbody className="flex-wrapper__modal-table">
                  {dataTables["execution"]
                    .filter((elem) => elem !== null)
                    .map((elem) => (
                      <ViewModalRow
                        title={elem[0]}
                        value={elem[1]}
                        color={elem[2]}
                      />
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </Grommet>

        {/* </div> */}
        <button
          onClick={modalHandler}
          className={`button button--olive`}
          style={{ marginTop: "10px" }}
        >
          Закрыть окно
        </button>
      </div>
    )
  );
};

export default LocationsViewModal;
